// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Poppins", sans-serif;
  transition: all 0.2s ease; /* Slightly slower transition for a smoother effect */
}

.field {
  padding: 12px; /* Added padding for better spacing */
  border: 1px solid #ccc; /* Border to make fields stand out */
  border-radius: 8px; /* Rounded corners for a softer look */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.field:hover {
  border-color: #007BFF; /* Change border color on hover */
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2); /* Enhanced shadow on hover */
}

.field:nth-of-type(2) {
  margin: 16px 0; /* Maintained margin for the second field */
}

/* Additional styling for inputs, buttons, or other elements can be added below */`, "",{"version":3,"sources":["webpack://./src/app2.js/css/with.css"],"names":[],"mappings":"AAEA;EACE,kCAAkC;EAClC,yBAAyB,EAAE,qDAAqD;AAClF;;AAEA;EACE,aAAa,EAAE,qCAAqC;EACpD,sBAAsB,EAAE,oCAAoC;EAC5D,kBAAkB,EAAE,sCAAsC;EAC1D,wCAAwC,EAAE,4BAA4B;AACxE;;AAEA;EACE,qBAAqB,EAAE,iCAAiC;EACxD,6CAA6C,EAAE,6BAA6B;AAC9E;;AAEA;EACE,cAAc,EAAE,2CAA2C;AAC7D;;AAEA,iFAAiF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap\");\n\n* {\n  font-family: \"Poppins\", sans-serif;\n  transition: all 0.2s ease; /* Slightly slower transition for a smoother effect */\n}\n\n.field {\n  padding: 12px; /* Added padding for better spacing */\n  border: 1px solid #ccc; /* Border to make fields stand out */\n  border-radius: 8px; /* Rounded corners for a softer look */\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */\n}\n\n.field:hover {\n  border-color: #007BFF; /* Change border color on hover */\n  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2); /* Enhanced shadow on hover */\n}\n\n.field:nth-of-type(2) {\n  margin: 16px 0; /* Maintained margin for the second field */\n}\n\n/* Additional styling for inputs, buttons, or other elements can be added below */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
