import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";
import axios from "axios";

const Support = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;
  const access_token = localStorage.getItem("token");
  const [user, setUser] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");

  const getUser = async () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const res = await axios.get(`${baseUrl}me`, { headers });
      setUser(res.data);
    } catch (e) {
      if (e.response?.status === 401) {
        localStorage.removeItem("token");
        // Redirect to login or handle token expiration
      } else {
        console.error("Error fetching user:", e);
      }
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${baseUrl}settings/data`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setWebsiteSettings(data);
    } catch (error) {
      console.error("Error fetching website settings:", error);
    }
  };

  useEffect(() => {
    fetchData();
    getUser();
  }, []); // Run on component mount only

  
  
  return (
   
<div className="container-fluid" style={{ backgroundColor: "#f8f9fa", minHeight: "100vh" }}>
<div className="cxy flex-column" style={{ paddingTop: "15%" }}>
  <div className="row justify-content-center">
    {/* Main content container */}
    <div className="col-md-8" style={{ minHeight: "100vh" }}>
      {/* Support section header */}
      <div className="text-center" style={{ paddingTop: "5%", paddingBottom: "3%" }}>
        <h1 className="display-4" style={{ fontWeight: 700, color: "#333", letterSpacing: "1px" }}>
          We're Here to Help!
        </h1>
        <p style={{ fontSize: "1.1em", color: "#666" }}>
          Contact us for any support from Monday to Saturday, 11:00 AM to 11:00 PM
        </p>
        <span style={{ color: "red", fontSize: "0.9em" }}>
          Our support timing is from 11:00 AM to 11:00 PM
        </span>
      </div>

      {/* Cards section for contact options */}
      <div className="row justify-content-center">
        {/* WhatsApp and Instagram Row */}
        <div className="col-6 mb-3">
          <div className="card text-center p-3" style={{ borderRadius: "8px", border: "1px solid #ddd" }}>
           <a
              href="https://whatsapp.com/channel/0029Var4q2bLY6d4AMR4h33z"
              target="_blank"
              rel="noreferrer"
            >
           {/*             <a href={`https://wa.me/9588925620?text=hello%20admin%20i%20need%20help%20%3F%20phone=${user?.Phone}`} target="_blank" rel="noreferrer">
*/}
              <button
                className="btn btn-block"
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  border: "1px solid #ccc",
                  padding: "10px 20px",
                  width: "100%",
                }}
              >
                <img
                  width="30px"
                  src="https://i.postimg.cc/VkfPKDxb/whatsapp.png"
                  alt="WhatsApp"
                />
                <span className="ml-2">WhatsApp</span>
              </button>
            </a>
          </div>
        </div>

        <div className="col-6 mb-3">
          <div className="card text-center p-3" style={{ borderRadius: "8px", border: "1px solid #ddd" }}>
            <a
              href="https://instagram.com/ludopay.in"
              target="_blank"
              rel="noreferrer"
            >
              <button
                className="btn btn-block"
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  border: "1px solid #ccc",
                  padding: "10px 20px",
                  width: "100%",
                }}
              >
                <img
                  width="30px"
                  src="https://i.postimg.cc/Y95w8k7p/instagram.png"
                  alt="Instagram"
                />
                <span className="ml-2">Instagram</span>
              </button>
            </a>
          </div>
        </div>

        {/* Telegram and Email Row */}
        <div className="col-6 mb-3">
          <div className="card text-center p-3" style={{ borderRadius: "8px", border: "1px solid #ddd" }}>
            <a
              href="https://t.me/ludo_pay_official"
              target="_blank"
              rel="noreferrer"
            >
              <button
                className="btn btn-block"
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  border: "1px solid #ccc",
                  padding: "10px 20px",
                  width: "100%",
                }}
              >
                <img
                  width="30px"
                  src="https://i.postimg.cc/02qsw8Zn/Telegram.png"
                  alt="Telegram"
                />
                <span className="ml-2">Telegram</span>
              </button>
            </a>
          </div>
        </div>

        <div className="col-6 mb-3">
          <div className="card text-center p-3" style={{ borderRadius: "8px", border: "1px solid #ddd" }}>
            <a href={`mailto:${WebSitesettings.CompanyEmail}`} target="_blank" rel="noreferrer">
              <button
                className="btn btn-block"
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  border: "1px solid #ccc",
                  padding: "10px 20px",
                  width: "100%",
                }}
              >
                <img
                  width="30px"
                  src="https://i.postimg.cc/J7X80y3t/email.png"
                  alt="Email"
                />
                <span className="ml-2">{WebSitesettings.CompanyEmail}</span>
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* Footer for address */}
      <div className="text-center mt-4">
        <h5 style={{ color: "#000" }}>{WebSitesettings ? WebSitesettings.CompanyName : ""}</h5>
        <p style={{ color: "#555" }}>{WebSitesettings ? WebSitesettings.CompanyAddress : ""}</p>
      </div>
    </div>
  </div>
</div>
</div>
  );
};
export default Support;
