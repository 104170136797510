import React, { useEffect, useState } from "react";
import css from "../css/gamehis.module.css";
import axios from "axios";
import { Link } from 'react-router-dom';

const Referralhis = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [user, setUser] = useState();

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    await axios.get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
        Allgames(res.data.referral_code);
      });
  };

  const [cardData, setGame] = useState([]);

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    await axios.get(baseUrl + `referral/code/winn/${id}`, { headers })
      .then((res) => {
        setGame(res.data);
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString('default', { month: 'long', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
    return newDate;
  };

  useEffect(() => {
    role();
    // eslint-disable-next-line
  }, []);

  if (cardData === undefined) {
    return null;
  }

  return (
<div>
  <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
    <div className="pt-5 mb-3"></div>
      <div 
  className="card mt-2 p-2" 
  style={{
    backgroundColor: "#333333", 
    borderRadius: "1px", 
    padding: "10px", 
    margin: "0 auto"
  }}
>
<div className="d-flex justify-content-between">
       <Link 
  to="/Gamehistory"
  className={css.Link}
>
   <span className="font-9" style={{ fontWeight: '500', color: 'black' }}>Game</span>
</Link>

<Link 
  to="/transaction-history"
  className={css.Link}
>
  <span className="font-9" style={{ fontWeight: '500', color: 'black' }}>Wallet </span>
</Link>

<Link 
  to="/Referral-history"
  className={css.Linked}
>
   <span className="font-9" style={{ fontWeight: '500', color: 'white' }}>Referral </span>
</Link>
</div></div>   
    {/* Referral earnings cards */}
    {cardData && cardData.length > 0 ? cardData.map((card) => (
      <div
        className={`w-100 py-2 d-flex align-items-center ${css.list_item}`}
        key={card._id}
        style={{
          backgroundColor: '#f9f9f9',  // Lighter background
          borderRadius: '10px',  // Rounded corners for a modern feel
          border: '1px solid #ddd',  // Light border
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',  // Subtle shadow for a soft effect
          color: '#333',  // Darker text color for contrast
          marginBottom: '10px',  // Space between cards
        }}
      >
        {/* Referral Details */}
        <div className={`mx-3 d-flex ${css.list_body}`}>
          <div className="d-flex align-items-center">
            <picture className="mr-2">
              <img
                height="32px"
                width="32px"
                src="https://Ludopay.in/ludo.jpeg"
                alt=""
                style={{ borderRadius: "5px" }}
              />
            </picture>
          </div>

          <div className="d-flex flex-column font-8">
            <div style={{ fontSize: '0.85rem', fontWeight: 'bold' }}>
              Referral Earning
            </div>
            <div className={`${css.games_section_headline}`} style={{ fontSize: '0.75rem', color: '#555' }}>
              Earned by: <b>{card.earned_from.Name}</b>
            </div>

            {/* Date and Time Section below the username */}
            <div style={{ fontSize: '0.75rem', color: '#555', marginTop: '4px' }}>
              {dateFormat(card.createdAt)} {/* Full Date and Time */}
            </div>
          </div>
        </div>

        {/* Earning Details */}
        <div className="right-0 d-flex align-items-end pr-3 flex-column" style={{ marginLeft: 'auto' }}>
          <div className="d-flex float-right font-8" style={{ fontSize: '0.85rem' }}>
            <picture className="ml-1 mb-1">
              <img
                height="18px"
                width="18px"
                src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp"
                alt=""
              />
            </picture>
            <span className="pl-1"><b>{card.amount.toFixed(0)}</b></span>
          </div>
<div
  className="games-section-headline"
  style={{
    fontSize: "0.6rem", // Reduced font size
    whiteSpace: 'nowrap',
    color: '#777',
  }}
>
  Closing Balance: {card.closing_balance.toFixed(1)}
</div>

        </div>
      </div>
    )) : (
      <div className="text-center" 
           style={{ 
             backgroundColor: '#f9f9f9',  // Lighter background
             borderRadius: '10px',  // Rounded corners
             padding: '20px',
             boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',  // Subtle shadow for a soft effect
             color: '#333',  // Darker text color
           }}>
        <img
          src={process.env.PUBLIC_URL + '/Images/notransactionhistory.png'}
          alt="no data"
          width={250}
          height={250}
          className="img-fluid"
          style={{ marginTop: "20%" }}
        />
        <div className="mt-2">
          <h3 className="font-weight-bold" style={{ color: '#333' }}>No Referral History</h3>
          <p className="text-muted">You have not made any referrals yet.</p>
        </div>
      </div>
    )}
  </div>
</div>
  );
};

export default Referralhis;