import React, { useState } from "react";
import styles from "../css/notification.module.css";

const Notification = () => {
  const [showCanvas, setShowCanvas] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isClosing, setIsClosing] = useState(false); // New state for slide-out effect

  const handleShowCanvas = () => {
    setShowCanvas(true);
    setIsButtonClicked(true);
  };

  const handleCloseCanvas = () => {
    setIsClosing(true); // Trigger slide-out effect
    setTimeout(() => {
      setShowCanvas(false); // Hide offcanvas after slide-out animation
      setIsClosing(false);
    }, 300); // Duration of the slide-out animation
  };

  return (
    <div className={styles.notificationContainer}>
      {/* Notification Card */}
      <div className={styles.notificationCard}>
        <div className={styles.notificationBar}></div>
        <div className={styles.notificationContent}>
          <img
            src={process.env.PUBLIC_URL + "/Images/nonotification.png"}
            alt="No Notification"
            className={styles.notificationImg}
          />
          <h5 className={styles.notificationTitle}>No notifications yet!</h5>
          <p className={styles.notificationDescription}>
            Seems like you haven’t done any activity yet.
          </p>
          
          {/* Updated Button to trigger offcanvas */}
          <button
            className={`${styles.notificationButton} ${
              isButtonClicked ? styles.clicked : ""
            }`}
            onClick={handleShowCanvas}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              width="1em"
              height="1em"
              fill="#FF0000"
              className="me-1"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
            </svg>
            &nbsp; How to Play
          </button>
        </div>
      </div>

      {/* Offcanvas */}
      {showCanvas && (
        <div
          className={styles.offcanvasBackdrop}
          onClick={handleCloseCanvas}
        >
          <div
            className={`${styles.offcanvas} ${isClosing ? styles.slideOut : styles.slideIn}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.offcanvasHeader}>
              <h5>How to Play</h5>
              <button
                className={styles.offcanvasClose}
                onClick={handleCloseCanvas}
              >
                ×
              </button>
            </div>
            <iframe
              src="https://www.youtube.com/embed/N4tUSsOih7M"
              title="How to Play"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notification;