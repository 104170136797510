import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Transactionhistory = () => {
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;

  const [user, setUser] = useState();
  const limit = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [cardData, setCardData] = useState([]);
  const [showWithdrawalHistory, setShowWithdrawalHistory] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  useEffect(() => {
    role();
  }, [pageNumber, limit, showWithdrawalHistory]);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const role = async () => {
    setLoading(true);
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    try {
      const res = await axios.get(`${baseUrl}me`, { headers });
      setUser(res.data);
      if (showWithdrawalHistory) {
        fetchWithdrawalHistory(res.data._id);
      } else {
        fetchDepositHistory(res.data._id);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchDepositHistory = async (id) => {
    setLoading(true);
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    try {
      const res = await axios.get(`${baseUrl}temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`, { headers });
      setCardData(res.data.ress);
      setNumberOfPages(res.data.totalPages);
    } finally {
      setLoading(false);
    }
  };

  const fetchWithdrawalHistory = async (id) => {
    setLoading(true);
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    try {
      const res = await axios.get(`${baseUrl}temp/withdrawal/${id}?page=${pageNumber}&_limit=${limit}`, { headers });
      setCardData(res.data.ress);
      setNumberOfPages(res.data.totalPages);
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (data) => {
    setPageNumber(data.selected + 1);
  };

  const toggleHistoryType = () => {
    setShowWithdrawalHistory(!showWithdrawalHistory);
  };

  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("default", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
  };

  const checkupigatewaypay = (order_id, order_token, pay_date) => {
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    axios
      .post(`${baseUrl}depositupipay/response`, { order_id, order_token }, { headers })
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title = res.data.status === "PAID" ? "Deposit submitted successfully" : "Transaction Failed";
        role(); // Refresh transaction history
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          window.location.reload();
        }
      });
  };

  return (
    <div className="leftContainer" style={{ minHeight: '100vh', paddingBottom: '30px' }}>
      <ToastContainer />
      <div className="pt-5">
        <div className="mt-4">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>

{cardData && cardData.length > 0 ? (
  cardData.map((card) => {
    if (card.status === 'FAILED') return null;

    const id = card._id.toString();
    const timestamp = id.slice(0, 8);
    const counterid = parseInt(timestamp, 16);

    let titleMsg = "";
    let signIcon = null;
    let statusBgColor = '';  
    let statusTextColor = 'white';

    if (card.Req_type === 'deposit' || card.Req_type === 'bonus') {
      titleMsg = 'Cash added';
      signIcon = (
        <div className={`text-${card.status === 'Pending' ? 'danger' : 'success'}`}>
          {card.status === 'Pending' ? (
            <img src="https://i.postimg.cc/R0j1NZNQ/Minusicon.png" alt="minus icon" width="12" height="12" />
          ) : (
            <img src="https://i.postimg.cc/rmFVG8B7/Plusicon.png" alt="plus icon" width="12" height="12" />
          )}
        </div>
      );
      statusBgColor = card.status === 'Pending' ? '#808080' : '#388E3C';  // Dark green for paid
    } else if (card.Req_type === 'withdraw') {
      titleMsg = `Withdraw using ${card.Withdraw_type}`;
      signIcon = (
        <div className="text-danger">
          <img src="https://i.postimg.cc/R0j1NZNQ/Minusicon.png" alt="minus icon" width="12" height="12" />
        </div>
      );
      statusBgColor = card.status === 'Processing' ? '#808080' : (card.status === 'success' ? '#8BC34A' : '#ff6f61'); // Grey for Processing, light green for success
    } else if (card.Req_type === 'penalty') {
      titleMsg = 'Penalty';
      signIcon = (
        <img
          className='mx-1'
          src="https://i.postimg.cc/R0j1NZNQ/Minusicon.png"
          width='12px'
          alt=''
        />
      );
      statusBgColor = '#FFA500'; // Orange for penalty
    } else {
      titleMsg = 'Unknown';
      signIcon = <div className="text-success"></div>;
      statusBgColor = '#f5f5f5'; 
      statusTextColor = '#333';
    }

    return (
      <div key={card._id} className="w-96 my-1 py-2 px-3 d-flex justify-content-between align-items-center rounded"
        style={{
          backgroundColor: '#ffffff',
          borderRadius: '4px',  // Reduced border radius for sharper look
          border: '1px solid #cccccc',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
          color: '#333',
          marginBottom: '8px',  // Smaller card spacing
        }}
      >
        <div className="d-flex flex-column" style={{ alignItems: 'flex-start' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <b style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>{titleMsg}</b>
          </div>
          <h6 style={{ fontSize: '0.7rem', color: '#555' }}>OrderID: JP-{counterid}</h6>
          <div className="d-flex justify-content-between" style={{ width: '100%' }}>
            <div style={{ fontSize: '0.75rem', fontWeight: 'bold' }}>{dateFormat(card.createdAt).split(',')[0]}</div>
            <small style={{ fontSize: '0.65rem' }}>{dateFormat(card.createdAt).split(',')[1]}</small>
          </div>
          {card.Req_type === 'penalty' && (
                  <small style={{ fontSize: '0.35rem', color: '#888' }}>
                    Reason: wrong Result or not update
                  </small>
                )}
              </div>

        <div className="d-flex flex-column align-items-end">
          <div style={{
            fontSize: '0.6rem',  // Reduced font size for smaller appearance
            fontWeight: 'bold',
            marginBottom: '4px',
            padding: '4px 8px',
            backgroundColor: statusBgColor,
            borderRadius: '3px', // Smaller rounded corners
            color: statusTextColor,
            textAlign: 'center'
          }}>
            {card.status === 'Pending' && card.Req_type === 'withdraw' ? 'Processing' : card.status}
          </div>
          <div className="d-flex align-items-center">
            {signIcon}
            <img
              src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp"
              width="16px"
              alt=""
              style={{ marginRight: '1px' }}
            />
            <span
              style={{
                fontSize: '0.85rem',
                fontWeight: 'bold',
                color:
                  card.Req_type === 'withdraw'
                    ? (card.status === 'success' ? '#8BC34A' : '#FF5733')
                    : card.Req_type === 'penalty'
                    ? 'orange'
                    : '#4caf50',
                marginLeft: '1px'
              }}
            >
             <b> {card.amount} </b>
            </span>
          </div>

                {/* Check button for Pending deposit only */}
<div>
  {isButtonVisible && card.Req_type === "deposit" && card.status === "Pending" && (
    <button
      className="btn btn-sm btn-danger text-white px-2 py-1"
      onClick={() => {
        checkupigatewaypay(
          card.order_id,
          card.order_token,
          dateFormat(card.createdAt)
        );
        toast.success("Status checked ✅");
        setIsButtonVisible(false); // Hide button after click
      }}
    >
      <strong>Check Status</strong>
    </button>
  )}
</div>
              </div>
            </div>
          );
        })
      ) : (
<div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
  <img
    src={process.env.PUBLIC_URL + '/Images/notransactionhistory.png'}
    alt="no data"
    width={250}
    height={250}
    className="img-fluid"
    style={{ marginTop: "20%" }}
  />
  <div className="mt-3 text-center">
    <h3 className="font-weight-bold" style={{ color: '#333' }}>No Transaction History</h3>
    <p className="text-muted">You have not made any transaction yet.</p>
  </div>
</div>
      )}
    </div>
  );
};

export default Transactionhistory;