import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom"; // Changed back to useHistory
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/Loader.css';
import '../css/kyc.css';

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

const Kyc2 = ({ user }) => {
  const history = useHistory(); // Changed to useHistory

  const [frontLoaded, setFrontLoaded] = useState(null);
  const [backLoaded, setBackLoaded] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [dob, setDob] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUnderProcess, setIsUnderProcess] = useState(false);
  const [progress, setProgress] = useState(0);

  let aadharProcess = useRef(false);

  const handleAadharChange = (e) => {
    const value = e.target.value;
    if (value.length <= 12 && /^\d*$/.test(value)) {
      setNumber(value);
    }
  };

  const handleSubmitData = async (e) => {
    e.preventDefault();

    if (user.verified === "unverified") {
      if (!aadharProcess.current) {
        setIsSubmitting(true);
        aadharProcess.current = true;

        const formData = new FormData();
        formData.append("Name", name);
        formData.append("Email", email);
        formData.append("Number", number);
        formData.append("DOB", dob);
        formData.append("front", frontLoaded);
        formData.append("back", backLoaded);

        if (frontLoaded && backLoaded) {
          try {
            const access_token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${access_token}` };

            const response = await axios.post(`${baseUrl}aadharcard`, formData, {
              headers,
              onUploadProgress: progressEvent => {
                const total = progressEvent.total;
                const current = progressEvent.loaded;
                setProgress(Math.round((current / total) * 100));
              }
            });

            if (response.data.msg === false) {
              toast.error("Duplicate Entity");
            } else {
              toast.success("Your KYC form has been submitted");
              setIsUnderProcess(true);
              setTimeout(() => {
                history.push("/Profile"); // Use history.push in v5
              }, 2000);
            }
          } catch (error) {
            console.error(error);
            if (error.response?.status === 401) {
              localStorage.removeItem('token');
              history.push("/login");
            } else {
              toast.error("An error occurred during submission.");
            }
          } finally {
            setIsSubmitting(false);
            aadharProcess.current = false;
            setProgress(0); // Reset progress
          }
        } else {
          toast.error('Please fill all fields');
          setIsSubmitting(false);
          aadharProcess.current = false;
          setProgress(0);
        }
      } else {
        toast.info('You have already submitted the request.');
      }
    } else {
      toast.warning('Your request is already in process.');
    }
  };

  useEffect(() => {
    const access_token = localStorage.getItem('token');
    if (!access_token) {
      history.push("/login");
    }

    const frontPhoto = document.getElementById('frontPhoto');
    frontPhoto.onchange = e => {
      const [file] = frontPhoto.files;
      setFrontLoaded(file);
    };
    
    const backPhoto = document.getElementById('backPhoto');
    backPhoto.onchange = e => {
      const [file] = backPhoto.files;
      setBackLoaded(file);
    };
  }, [history]);

  return (
    <div className="kyc-container">
      <ToastContainer
        style={{ marginBottom: '25px' }}
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <br />
      <div 
        className="card mt-5" 
        style={{
          borderRadius: '4px', 
          overflow: 'hidden', 
          width: '100%', 
          maxWidth: '600px', 
          margin: '0 auto', 
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        }}
      >
        <div 
          className="card-header text-center" 
          style={{ 
            fontSize: '14px', 
            letterSpacing: '0.5px', 
            backgroundColor: '#E8ECEF', 
            padding: '12px 16px', 
            textAlign: 'center', 
            color: '#000',
            borderBottom: '1px solid #ddd'
          }}
        >
          Off-line KYC
        </div>
        <div className="card-body" style={{ padding: '20px' }}>
          <form onSubmit={handleSubmitData} className="kyc-form">
            <p className="instruction-text" style={{ fontSize: '12px', color: '#555' }}>
              Enter details of your Aadhar Card:
            </p>
            <div className="input-group">
              <label htmlFor="name" style={{ fontSize: '12px' }}>Full Name</label>
              <input
                type="text"
                id="name"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                style={{ fontSize: '12px' }}
              />
            </div>
            <div className="input-group">
              <label htmlFor="email" style={{ fontSize: '12px' }}>Email ID</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ fontSize: '12px' }}
              />
            </div>
            <div className="input-group">
              <label htmlFor="dob" style={{ fontSize: '12px' }}>Date of Birth</label>
              <input
                type="date"
                id="dob"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                required
                style={{ fontSize: '12px' }}
              />
            </div>
            <div className="input-group">
              <label htmlFor="number" style={{ fontSize: '12px' }}>Aadhar Number</label>
              <input
                type="tel"
                id="number"
                placeholder="Enter your 12-digit Aadhar Number"
                value={number}
                onChange={handleAadharChange}
                required
                style={{ fontSize: '12px' }}
              />
            </div>
            <div className="file-upload-group">
              <label htmlFor="frontPhoto" style={{ fontSize: '12px' }}>Upload Front Photo of Aadhar Card</label>
              <input id="frontPhoto" type="file" accept="image/*" required />
              {frontLoaded && <p className="file-name" style={{ fontSize: '12px' }}>{frontLoaded.name}</p>}
            </div>
            <div className="file-upload-group">
              <label htmlFor="backPhoto" style={{ fontSize: '12px' }}>Upload Back Photo of Aadhar Card</label>
              <input id="backPhoto" type="file" accept="image/*" required />
              {backLoaded && <p className="file-name" style={{ fontSize: '12px' }}>{backLoaded.name}</p>}
            </div>
            <button 
              type="submit" 
              className="submit-button" 
              disabled={isSubmitting}
              style={{ fontSize: '12px' }}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
            {isUnderProcess && <p className="process-message" style={{ fontSize: '12px' }}>KYC is under process...</p>}
            {isSubmitting && 
              <div className="progress-bar-container">
                <div className="progress-bar" style={{ width: `${progress}%` }}></div>
              </div>
            }
            {isSubmitting && (
              <p className="blinking-message" style={{ fontSize: '12px' }}>Submitting, please wait...</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Kyc2;