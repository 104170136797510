import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import '../css/layout.css';
import '../css/login.css';

const Login = () => {
  const history = useHistory();
  const [Phone, setPhone] = useState('');
  const [twofactor_code, settwofactor_code] = useState('');
  const [otp, setOtp] = useState(false);
  const [secretCode, setSecretCode] = useState('');
  const [referral, setReferral] = useState(useLocation().pathname.split("/")[2]);
  const [timer, setTimer] = useState(35);
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [buttonText, setButtonText] = useState('Continue');
  let timerInterval;

  useEffect(() => {
    return () => clearInterval(timerInterval);
  }, []);

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    setButtonText('');

    if (!Phone) {
      showToast('Please enter your phone number', 'error');
      resetButton();
    } else if (Phone.length !== 10) {
      showToast('Please enter a valid phone number', 'error');
      resetButton();
    } else {
      try {
        const response = await axios.post('https://backendapi.ludoplayers.live/login', {
          Phone,
          referral
        });

        if (response.data.status === 101) {
          showToast(response.data.msg, 'error');
          resetButton();
        } else if (response.data.status === 200) {
          setOtp(true);
          setOtpSent(true);
          setSecretCode(response.data.secret);
          startTimer();
          showToast('OTP sent to mobile number', 'info');
          resetButton();
        }
      } catch (error) {
        showToast('Something went wrong', 'error');
        resetButton();
      }
    }
  };

  const startTimer = () => {
    let seconds = 35;
    timerInterval = setInterval(() => {
      if (seconds > 0) {
        setTimer(seconds);
        seconds--;
      } else {
        clearInterval(timerInterval);
        setTimer(0);
        setOtpSent(false);
      }
    }, 1000);
  };

  const resendOTP = async () => {
    if (!otpSent) {
      try {
        const response = await axios.post('https://backendapi.ludoplayers.live/login', {
          Phone,
          referral
        });

        if (response.data.status === 200) {
          setOtpSent(true);
          startTimer();
        }
      } catch (error) {
        showToast('Failed to resend OTP', 'error');
      }
    } else {
      showToast('OTP already sent. Please wait for the timer to expire.', 'info');
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();

    if (!Phone) {
      showToast('Please enter your phone number', 'error');
    } else {
      try {
        setLoadingVerify(true);
        const response = await axios.post('https://backendapi.ludoplayers.live/login/finish', {
          Phone,
          twofactor_code,
          referral,
          secretCode
        });

        if (response.data.status === 101) {
          showToast(response.data.msg, 'error');
        } else if (response.data.status === 200) {
          const token = response.data.token;
          localStorage.setItem('token', token);
          window.location.reload(true);
          setTimeout(() => history.push('/Games'), 1000);
          showToast('Login successful!', 'success');
        } else {
          showToast('Something went wrong!', 'error');
        }
      } catch (error) {
        showToast('Something went wrong!', 'error');
      } finally {
        setLoadingVerify(false);
      }
    }
  };

  const showToast = (message, type) => {
    toast(message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      type: type,
    });
  };

  const resetButton = () => {
    setLoading(false);
    setButtonText('Continue');
  };

  return (

    <>

<div className='leftContainer' style={{ minHeight: '100vh', background: 'linear-gradient(135deg, #fff 0%, #1a1a1a 100%)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

  <div style={{ overflowY: 'hidden' }}>
    <div className="position-absolute w-100 center-xy mx-auto" style={{ top: '30%', zIndex: 9 }}>
<div className="bg-white px-3 cxy flex-column" style={{ width: '85%', borderRadius: '10px', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', position: 'relative' }}>
  <div className="card-header text-center" style={{ fontWeight: 'bold', fontSize: '14px', letterSpacing: '0.9px', backgroundColor: '#f0f0f0', padding: '8px 16px', textAlign: 'center', color: '#333333', width: '100%', margin: '0 0 20px 0', boxSizing: 'border-box', borderRadius: '4px' }}>Sign in Or Sign Up</div>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <div className="input-group-text" style={{ backgroundColor: '#000', color: '#fff', borderRadius: '4px' }}>+91</div>
          </div>
          <input className="form-control" name="mobile" type="tel" placeholder="Mobile number"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            style={{ borderRadius: '4px', border: '1px solid #000' }}
          />
        </div>

        {otp && (
          <div className={`input-group mb-3 slide-in`}>
            <div className="input-group-prepend">
              <div className="input-group-text" style={{ backgroundColor: '#000', color: '#fff', borderRadius: '4px' }}>OTP</div>
            </div>
            <input
              className="form-control"
              name="otp"
              type="tel"
              placeholder="Enter OTP"
              onChange={(e) => settwofactor_code(e.target.value)}
              style={{ borderRadius: '4px', border: '1px solid #000' }}
            />
          </div>
        )} 

        {!loading && !otp && (
          <button
            className="Login-button btn w-100"
            onClick={(e) => {
              handleClick(e);
              e.currentTarget.style.transform = 'scale(0.98)';
            }}
            style={{
              borderRadius: '4px',
              padding: '10px 0',
              fontSize: '14px',
              backgroundColor: '#505050',
              border: 'none',
              color: '#ffffff',
              fontWeight: 'bold',
              boxShadow: '0 3px 6px rgba(0, 0, 0, 0.7)',
              transition: 'background-color 0.3s, transform 0.2s',
            }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#424242')}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#505050')}
          >
            <span style={{ color: '#fff', fontWeight: 'bold', fontSize: '13px' }}>{buttonText}</span>
          </button>
        )}

        {!loading && otp && (
          <button
            className="Login-button btn w-100"
            onClick={(e) => {
              verifyOtp(e);
              e.currentTarget.style.transform = 'scale(0.98)';
            }}
            style={{
              borderRadius: '4px',
              padding: '10px 0',
              fontSize: '14px',
              backgroundColor: '#6c6c6c',
              border: 'none',
              color: '#ffffff',
              fontWeight: 'bold',
              boxShadow: '0 3px 6px rgba(0, 0, 0, 0.7)',
              transition: 'background-color 0.3s, transform 0.2s',
            }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#357a38')}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#4caf50')}
          >
            {loadingVerify ? (
              <div className="spinner-border text-light" role="status"></div>
            ) : (
              <span style={{ color: '#fff', fontWeight: 'bold', fontSize: '13px' }}>Verify OTP</span>
            )}
          </button>
        )}

        {loading && (
          <button
            className="Login-button btn w-100"
            disabled
            style={{
              borderRadius: '4px',
              padding: '12px 0',
              fontSize: '16px',
              backgroundColor: '#000',
              border: 'none',
              color: '#ffffff',
              boxShadow: '0 3px 6px rgba(0, 0, 0, 0.7)',
            }}
          >
            <div className="spinner-border text-light" role="status"></div>
          </button>
        )}

        {otp && timer > 0 && <div className="text-center mt-3 text-dark">Didn't receive OTP? Retry in ( {timer} ) seconds</div>}
        {otp && timer === 0 && (
          <div className="text-center mt-3">
            <a href="#resend-otp" onClick={resendOTP} style={{ color: '#606060', textDecoration: 'underline' }}>Resend OTP</a>
          </div>
        )}
      </div>
    </div>
  </div>
  <div className="login-footer" style={{ color: 'white', fontWeight: 'bold' }}>
    By continuing, I agree to the <Link to="/term-condition" style={{ color: 'black' }}>Terms of Use</Link> and <Link to="/PrivacyPolicy" style={{ color: 'black' }}>Privacy Policy</Link>. I am 18 years or older and not playing from restricted states.
  </div>
</div>
<ToastContainer
  style={{ marginTop: '1px' }}
  position="top-right"
  autoClose={3000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
/>
    </>
  );
}

export default Login;   