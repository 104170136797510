import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css';
import Rightcontainer from '../Components/Rightcontainer';
import '../css/Loader.css';

function Return({ walletUpdate }) {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

    const history = useHistory();
    const url_string = window.location.href;
    const url = new URL(url_string);
    const client_txn_id = url.searchParams.get("client_txn_id");
    const txn_id = url.searchParams.get("txn_id");
    const order_id = client_txn_id && txn_id ? client_txn_id : url.searchParams.get("order_id");
    const order_token = client_txn_id && txn_id ? txn_id : url.searchParams.get("order_token");

    const [status, setStatus] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let access_token = localStorage.getItem('token');
        if (!access_token) {
            history.push("/login");
            window.location.reload();
        }

        const headers = {
            Authorization: `Bearer ${access_token}`
        };

        const paymentUrl = client_txn_id && txn_id ? `depositupipay/response` : `deposit/response`;

        // Show loading message to the user while waiting for the response
        toast.info('Processing payment... Please wait.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        axios.post(`${baseUrl}${paymentUrl}`, { order_id, order_token }, { headers })
            .then((res) => {
                const { status: paymentStatus } = res.data;
                setStatus(paymentStatus);

                if (paymentStatus === "PAID") {
                    walletUpdate()
                        .then(() => {
                            toast.success("Deposit submitted successfully!", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                            setLoading(false);
                            history.push('/landing');
                        })
                        .catch((error) => {
                            toast.error("Failed to update wallet. Please try again.", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                            console.error("Wallet update failed:", error);
                            setLoading(false);
                            history.push('/');
                        });
                } else {
                    toast.warning("Deposit processed, but there may be issues with updating wallet.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setLoading(false);
                    history.push('/');
                }
            })
            .catch((e) => {
                if (e.response && e.response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.reload();
                    history.push("/login");
                } else {
                    toast.error("An error occurred while processing the payment.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setLoading(false);
                    history.push('/');
                }
            });

    }, [order_id, order_token, baseUrl, history, walletUpdate]);

    return (
        <>
            <div className="leftContainer" style={{ position: 'relative', minHeight: '100vh', height: '100%', paddingTop: '60px' }}>
                {loading ? (
                    <div className="loaderReturn">
                        <p className="loaderText">
                            <strong>Payment Processing...</strong> Please wait. <br />
                            <em>For Confirmation</em>
                        </p>
                        <div className="progressBarContainer">
                            <div className="progressBox"></div>
                            <div className="progressBox"></div>
                            <div className="progressBox"></div>
                            <div className="progressBox"></div>
                            <div className="progressBox"></div>
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="rightContainer">
                <Rightcontainer />
            </div>
            <ToastContainer />
        </>
    );
}

export default Return;