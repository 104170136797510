
import React, { useState, useEffect } from "react";
import "../css/layout.css";
import "../css/homepage.css";
import css from "../css/with.css"
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import '../css/Loader.css'
import findGif from "../css/loading.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";import 'react-toastify/dist/ReactToastify.css';

const Withdrawopt = ({ walletUpdate }) => {
  const history = useHistory()
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }


  const access_token = localStorage.getItem("token")
  const [Id, setId] = useState(null)
  const [user, setUser] = useState()
  const [holder_name, setHolder_name] = useState();
  const [account_number, setAccount_number] = useState();
  const [ifsc_code, setIfsc_code] = useState();
  const [upi_id, setUpi_id] = useState();
  const [next, setNext] = useState(false);
  
  const [isCashFreePayoutActive, setCashFreePayoutActive] = useState(false);
  const [isRazorPayPayoutActive, setRazorPayPayoutActive] = useState(false);
  const [isDecentroPayoutActive, setDecentroPayoutActive] = useState(false);
  const [isManualPayoutActive, setManualPayoutActive] = useState(false);

  const [isRazorPayPayoutAuto, setRazorPayPayoutAuto] = useState(false);
  const [isDecentroPayoutAuto, setDecentroPayoutAuto] = useState(false);
  const [maxAutopayAmt,setMaxAutopayAmt]=useState(0);

  const [submitBtn,setSubmitBtn]=useState(true);
  
  useEffect(() => {
    let access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.get(baseUrl+`me`, { headers })
      .then((res) => {
        setUser(res.data)
        setId(res.data._id);
        setHolder_name(res.data.holder_name);
        setAccount_number(res.data.account_number);
        setIfsc_code(res.data.ifsc_code);
        setUpi_id(res.data.upi_id);
      }).catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.reload()
          history.push("/login")
        }
      });
      
      axios.get(baseUrl+`website/setting`)
      .then((res) => {
        //console.log(res);
        setCashFreePayoutActive(res.data.isCashFreePayoutActive)
        setRazorPayPayoutActive(res.data.isRazorPayPayoutActive)
        setDecentroPayoutActive(res.data.isDecentroPayoutActive)
        setManualPayoutActive(res.data.isManualPayoutActive)
        
        setRazorPayPayoutAuto(res.data.isRazorPayPayoutAuto)
        setDecentroPayoutAuto(res.data.isDecentroPayoutAuto)
        setMaxAutopayAmt(res.data.maxAutopayAmt)

      }).catch((e) => {
        setManualPayoutActive(false)
        setCashFreePayoutActive(false)
        setRazorPayPayoutActive(false)
        setDecentroPayoutActive(false)
        setMaxAutopayAmt(0)
      })
  }, [])

const updateBankDetails = async () => {
  setMount(true);
  setSubmitBtn(false);
  let confirm = false;

  if (type === 'upi' || type === 'manualupi') {
    let regex = /^[\w.-]+@[\w.-]+$/.test(upi_id);

    if (regex === true) {
      toast.success(`Your UPI ID is correct: ${upi_id}`, {
        position: "top-right",
        theme: "dark",
      });
      confirm = true;
    } else {
      toast.error(`Invalid UPI ID: ${upi_id}`, {
        position: "top-right",
        theme: "dark",
      });
      confirm = false;
      setSubmitBtn(true);
    }
  } else {
    if (!holder_name || !account_number || !ifsc_code) {
      setMount(false);
      setSubmitBtn(true);
      toast.error("Invalid Bank Details", {
        position: "top-right",
        theme: "dark",
      });
      confirm = false;
    } else {
      confirm = true;
    }
  
        //var confirmMsg = `Is your Bank Account Number is correct ? `+account_number;
    }
    
        if (confirm) {
        const access_token = localStorage.getItem("token")
        const headers = {
          Authorization: `Bearer ${access_token}`
        }
        const data = await axios.patch(baseUrl+`user/edit`, {
          holder_name, account_number, ifsc_code, upi_id, bankDetails: true
        }, { headers })
          .then((res) => {
            console.log('updata bank details', res);
            if (res.data.subCode === '200') {
              let calculatedWallet = ((user.wonAmount-user.loseAmount)+user.totalDeposit+user.referral_earning+user.hold_balance+user.totalBonus)-(user.totalWithdrawl+user.referral_wallet+user.totalPenalty);
              (calculatedWallet==user.Wallet_balance)?doAutoPayout():withReqComes();
            }

else {
  setMount(false);
  setSubmitBtn(true);
  toast.error(res.data.message);  // Using react-toastify to show the error message
}
})
.catch((e) => {
  console.log(e);
  if (e.response.status == 401) {
    localStorage.removeItem('token');
    localStorage.removeItem('token');
    window.location.reload();
    history.push("/login");
  }
})
          
        }else{
          setMount(false);
          setSubmitBtn(true);
        }
      
  }

  const [amount, setAmount] = useState()
  const [type, setType] = useState(undefined)
  const [mount, setMount] = useState(false);
  // const [accunt, setUpi] = useState()
  // const [account_no, setAccount_no] = useState()
  // const [IFSC, setIFSC] = useState();
  
  //this function for handleAuto payout service with payment gateway

  const doAutoPayout =()=>{
    if(isRazorPayPayoutAuto && type=='upi'){
      //alert('payoutFromRazorpay');
      if(amount <= maxAutopayAmt){
        // payoutFromRazorpay();
        withReqComes();
      }else{
        // payoutFromRazorpay();
        withReqComes();
      }
    }
    else if(isDecentroPayoutAuto && type=='banktransfer'){
      //alert('payoutFromDecentro');
      if(amount <= maxAutopayAmt){
        payoutFromDecentro();
      }else{
        withReqComes();
      }
    }
    else if(isManualPayoutActive && type=='manualupi'){
      //alert('payoutFromDecentro');
      if(amount <= maxAutopayAmt){
        payoutFromManual();
      }else{
        withReqComes();
      }
    }
    else{
      //alert('withReqComes');
      withReqComes();
    }
  }

 //use for Razorpay payout

  const payoutFromManual = () => {
    if ((amount && amount >= 190 && amount <= 10000) && type) {
      // e.preventDefault();
      const payment_gatway = 'manualupi';
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`,
      }

      axios.post(baseUrl+`withdraw/payoutmanualupi`, {
        amount, type, payment_gatway
      }, { headers })
        .then((response) => {
          walletUpdate()
          setMount(false);
          console.log(response.data)
          if (response.data.status === 'Processing') {
            setTimeout(() => {
     
axios.get(baseUrl + `manual/payoutstatus/${response.data._id}`, { headers })
  .then((res) => {
    //console.log(res);
    const icon = res.data.status === "SUCCESS" ? "success" : "danger";
    let msg = '';
    if (res.data && res.data.status === "SUCCESS") {
      msg = "Withdraw successfully done";
    } else if (res.data && res.data.status === "Processing") {
      msg = "Withdrawal transaction in process.";
    } else if (!res.data.status) {
      msg = "Withdraw request transaction Rejected";
    }

    history.push('/');
    setTimeout(() => {
      setMount(false);
      if (icon === 'success') {
        toast.success(msg, {
          position: "top-right",
          theme: "dark",
        });
      } else {
        toast.error(msg, {
          position: "top-right",
          theme: "dark",
        });
      }
    }, 1000);
  })
  .catch((error) => {
    setMount(false);
    toast.error('Error! Try after sometime.', {
      position: "top-right",
      theme: "dark",
    });
    console.log(error);
  });

}, 30000);
setMount(true);
}
else {
  toast.error(response.data.message, {
    position: "top-right",
    theme: "dark",
  });
}
}).catch((e) => {
  setMount(false);
  toast.error('Error! Try after sometime.', {
    position: "top-right",
    theme: "dark",
  });
  console.log(e);
});
    }else {
  setMount(false);
  let msg = "Enter all fields";
  if ((!amount) || (!type)) {
    msg = "Enter all fields";
  } else if (amount < 190 || amount > 10000) {
    msg = "Amount should be more than 190 and less than 10000.";
  }
  toast.error(msg, {
    position: "top-right",
    theme: "dark",
  }); 
    }

  }

  
  const payoutFromRazorpay = () => {
    if ((amount && amount >= 190 && amount <= 50000) && type) {
      // e.preventDefault();
      const payment_gatway = 'razorpay';
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`,
      }

axios.post(baseUrl + `withdraw/payoutrazorpaybank`, {
  amount, type, payment_gatway
}, { headers })
  .then((res) => {
    walletUpdate();
    setMount(false);
    console.log(res.data);
    if (res.data.subCode === '200') {
      console.log('cash res', res);
      toast.success(res.data.message, {
        position: "top-right",
        theme: "dark",
      });
    } else {
      toast.error(res.data.message, {
        position: "top-right",
        theme: "dark",
      });
    }
  }).catch((e) => {
    setMount(false);
    toast.error('Error! Try after sometime.', {
      position: "top-right",
      theme: "dark",
    });
    console.log(e);
  });

} else {
  setMount(false);
  let msg = "Enter all fields";
  if ((!amount) || (!type)) {
    msg = "Enter all fields";
  } else if (amount < 190 || amount > 50000) {
    msg = "Amount should be more than 190 and less than 50000.";
  }
  toast.error(msg, {
    position: "top-right",
    theme: "dark",
  });
}
}
  //use for Razorpay payout end
  
  //use for decentro payout
const payoutFromDecentro = () => {
  if ((amount && amount >= 190 && amount <= 50000) && type) {
    const payment_gatway = 'decentro';
    const access_token = localStorage.getItem('token');
    if (!access_token) {
      toast.error('Please login again', { position: "top-right", theme: "dark" });
      return;
    }

    const headers = { Authorization: `Bearer ${access_token}` };
    
    axios.post(baseUrl + `withdraw/payoutdecentrobank`, { amount, type, payment_gatway }, { headers })
      .then((res) => {
        console.log('Response:', res.data); // Log the full response
        setTimeout(() => {
          walletUpdate();
        }, 5000);
        setMount(false);
        if (res.data.subCode === '200') {
          toast.success(res.data.message, { position: "top-right", theme: "dark" });
        } else {
          toast.error(res.data.message, { position: "top-right", theme: "dark" });
        }
      })
      .catch((e) => {
        setMount(false);
        console.error('Error:', e); // Log the error
        toast.error('Error! Try after sometime.', { position: "top-right", theme: "dark" });
      });

  } else {
    setMount(false);
    let msg = "Enter all fields";
    if (!amount || !type) {
      msg = "Please fill in all fields.";
    } else if (amount < 190 || amount > 50000) {
      msg = "Please enter an amount between 190 and 50,000.";
    }
    toast.error(msg, { position: "top-right", theme: "dark" });
  }
};

  //use for decentro payout end 



  const handleSubmitdata = () => {
    if ((amount && amount >= 190 && amount <= 20000) && type) {
      // e.preventDefault();
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`,
      }

axios.post(baseUrl + `withdraw/bank`, {
  amount, type
}, { headers })
  .then((res) => {
    setTimeout(() => {
      walletUpdate();
    }, 5000);
    setMount(false);
    console.log(res.data);
    if (res.data.subCode === '200') {
      console.log('cash res', res);
      toast.success(res.data.message, {
        position: "top-right",
        theme: "dark",
      });
    } else {
      toast.error(res.data.message, {
        position: "top-right",
        theme: "dark",
      });
    }
  }).catch((e) => {
    setMount(false);
    toast.error('Error! Try after sometime.', {
      position: "top-right",
      theme: "dark",
    });
    console.log(e);
  });

} else {
  setMount(false);
  let msg = "Enter all fields";
  if ((!amount) || (!type)) {
    msg = "Enter all fields";
  } else if (amount < 190 || amount > 100000) {
    msg = "Amount should be more than 190 and less than 100000.";
  }
  toast.error(msg, {
    position: "top-right",
    theme: "dark",
  });
}
}

  const withReqComes = async () => {
    try {
      setMount(true);
      
      if(type=='upi'){
        var payment_gatway = 'manualupi';
      }
      else if(type=='manualupi'){
        var payment_gatway = 'manualupi';
      }
      else{
        var payment_gatway = 'decentro';
      }
      
      const access_token = localStorage.getItem("token")
      const headers = {
        Authorization: `Bearer ${access_token}`
      }
await axios.post(baseUrl + `withdraw/request`, {
  amount, type, payment_gatway
}, { headers })
  .then((res) => {
    walletUpdate();
    if (res.data.success) {
      toast.success(res.data.msg, {
        position: "top-right",
        theme: "dark",
      });
    } else {
      toast.error(res.data.msg, {
        position: "top-right",
        theme: "dark",
      });
    }          
    setMount(false);
  })
  .catch((e) => {
    console.log(e);
    toast.error('Error! Try after sometime.', {
      position: "top-right",
      theme: "dark",
    });
  })
} catch (error) {
  console.log(error);
  toast.error('Error! Try after sometime.', {
    position: "top-right",
    theme: "dark",
  });
}
}

  
  return (
    <>
         <ToastContainer
  style={{ marginBottom: '25px' }}
  position="top-right"
  autoClose={3000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
/>
      <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
        {Boolean(mount) && <div className="loaderReturn">
  <p className="loaderText">Payment Processing...</p>

  <div className="progressBarContainer">
    <div className="progressBox"></div>
    <div className="progressBox"></div>
    <div className="progressBox"></div>
    <div className="progressBox"></div>
    <div className="progressBox"></div>
  </div>
</div>}

<div className="container px-3 mt-4 py-5" style={{ height: "12px" }}>
  <div>
    <div className="mb-3 d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center justify-content-start">
        <button onClick={() => history.goBack()} className="btn btn-dark" style={{ backgroundColor: '#333', borderRadius: '5px' }}>
          <i className="fa fa-arrow-circle-left" style={{ color: 'white' }}></i>
          <span className="text-capitalize fw-bold" style={{ color: 'white' }}><b>BACK</b></span> 
        </button>
      </div>
      <Link 
        className="text-capitalize btn btn-outline-primary fw-bold" 
        to="/transaction-history" 
        style={{ color: '#333', borderColor: '#333', height: '31px', borderRadius: '5px' }}> 
        <span className="font-9" style={{ color: '#333' }}><b>Wallet history</b></span>
      </Link>
    </div>
  </div>
  <div className="row">
    <div className="col mx-auto">
      <div className="card mt-0" style={{ border: '1px solid #333', width: '100%', margin: '0 auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)' }}>
        <div className="card-header text-center" style={{ fontWeight: 'bold', fontSize: '14px', letterSpacing: '0.9px', backgroundColor: '#f0f0f0', padding: '7px 16px', color: 'black' }}>
    Payment Details</div>
        <div style={{backgroundColor: '#555', padding: '5px', textAlign: 'center'}}>
          <span className="text-white" style={{ fontSize: '0.9rem' }}>
            Withdrawal Cash: {(user ? user.withdrawAmount.toFixed(2) : 0)}
          </span>
        </div>
        <br />
        <div className="d-flex flex-row align-items-center justify-content-between mb-2" style={{ backgroundColor: '#FFF' }}> 
          <span style={{ fontSize: '0.9rem' }}> &nbsp; Minimum: 190</span>
          <span style={{ fontSize: '0.9rem' }}>Maximum: 1,00,000 &nbsp;</span>
        </div>
      </div>
    </div>
  </div>




          <div className="row">

            <div className="col mx-auto">
              <div className="card text-center mt-3">
                {user && user.verified === 'verified' && <div className="card-body " style={{ padding: "15px" }}>

                  <h4 className="pt-1 text-left">
                    <b>{type == undefined ? 'Choose withdrawal option.' : 'Withdraw through'}</b>
                  </h4>
                  {Boolean(!next) && <div>
                    {Boolean(isManualPayoutActive) && <div onClick={() => { setType("manualupi"); setNext(true) }} className="add-fund-box my-3" style={{ paddingTop: "0px", height: "60px", 
                    //pointerEvents: "none", opacity: "0.6"
                    }}>
                      <div
                        className="d-flex align-items-center"
                        style={{
                          backgroundColor: "#fafafa",
                          border: "1px solid grey",
                          borderRadius: "7px",
                        }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ height: "60px", display: "flex", textAlign: "center" }}
                        >
<img
  width="45px"
  src={process.env.PUBLIC_URL + '/UPI.png'}
  alt=""
  style={{
    marginLeft: "7px",
    paddingBottom: "10px",
    paddingLeft: "3px",
    paddingTop: "5px",
    filter: "brightness(0) invert(1)", // This will make the image appear white
  }}
/>
                        </div>
                        <div className="d-flex justify-content-center flex-column ml-4">
                          <div className="text-left">
                            <strong>Withdraw through UPI</strong>
                          </div>
                          <div className="jss31"></div>
                        </div>
                      </div>
                    </div>}

                    {Boolean(isRazorPayPayoutActive) && <div onClick={() => { setType("upi"); setNext(true) }} className="add-fund-box my-3" style={{ paddingTop: "0px", height: "60px", 
                    //pointerEvents: "none", opacity: "0.6"
                    }}>
<div
  className="d-flex align-items-center"
  style={{
    backgroundColor: "#333",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "5px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  }}
>
  <div
    className="d-flex align-items-center"
    style={{
      height: "60px",
      textAlign: "center",
      marginRight: "10px",
    }}
  >                          <img
                            width="45px"
                            src={process.env.PUBLIC_URL + '/UPI.png'}
                            alt=""
                            style={{
                              marginLeft: "7px",
                              paddingBottom: "10px",
                              paddingLeft: "3px",
                              paddingTop: "5px",
                            }}
                          />
                        </div>
                        <div className="d-flex justify-content-center flex-column ml-4">
                          <div className="text-left">
                             <strong style={{ fontSize: "1.1rem", color: "#fff" }}>Withdraw through UPI</strong>
                            <br />
                      <small className="text-success" style={{ fontSize: "0.9rem" }}>
            Manual Withdraw within 10 minutes
      </small>             <br/>
                          </div>
                          <div className="jss31"></div>
                        </div>
                      </div>
                    </div>}
                    
                  
                    {Boolean(isDecentroPayoutActive) && <div onClick={() => { setType("banktransfer"); setNext(true) }} className="add-fund-box my-3" style={{ paddingTop: "0px", height: "60px" }}>
<div
  className="d-flex align-items-center"
  style={{
    backgroundColor: "#333",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "5px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  }}
>
  <div
    className="d-flex align-items-center"
    style={{
      height: "60px",
      textAlign: "center",
      marginRight: "10px",
    }}
  >
    <img
      width="35px"
      src={process.env.PUBLIC_URL + "/Bank.png"}
      alt=""
      style={{
        marginLeft: "7px",
        paddingBottom: "10px",
        paddingLeft: "3px",
        paddingTop: "5px",
      }}
    />
  </div>
  <div className="d-flex justify-content-center flex-column ml-4">
    <div className="text-left">
      <strong style={{ fontSize: "1.1rem", color: "#fff" }}>IMPS/Bank Transfer</strong>
      <br />
      <small className="text-success" style={{ fontSize: "0.9rem" }}>
        Auto Withdraw IMPS in 2 minutes
      </small>
      
    </div>

  
                          <div className="jss31"></div>
                        </div>
                      </div>
                    </div>}
                  </div>}


                  {Boolean(next) && <div >
                    <div className="row bg-light p-1">

                    
                      <div className="text-left w-100" style={{ background: 'white' }}>
                     
                        {Boolean(isManualPayoutActive) && Boolean(type == "manualupi") && <div className="add-fund-box my-3" style={{ paddingTop: "0px", height: "60px" }}>
                          <div
                            className="d-flex align-items-center"
                            style={{
                              backgroundColor: "#fafafa",
                              border: "1px solid grey",
                              borderRadius: "7px",
                            }}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{ height: "60px", display: "flex", textAlign: "center" }}
                            >
                              <img
                                width="45px"
                                src={process.env.PUBLIC_URL + '/UPI.png'}
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center flex-column ml-4">
                              <div className="text-left">
                                  <strong style={{ fontSize: "1.1rem", color: "#fff" }}>Withdraw through UPI</strong>
                              </div>
                              <div className="jss31"></div>
                            </div>
                            <button onClick={() => { setNext(false) }} className="btn btn-primary text-white font-weight-bold  ml-auto mr-3" style={{ fontSize: '0.5rem' }}>Edit</button>
                          </div>
                        </div>}
                        
                        
                        {Boolean(isRazorPayPayoutActive) && Boolean(type == "upi") && <div className="add-fund-box my-3" style={{ paddingTop: "0px", height: "60px" }}>
<div
  className="d-flex align-items-center"
  style={{
    backgroundColor: "#333",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "5px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  }}
>
  <div
    className="d-flex align-items-center"
    style={{
      height: "60px",
      textAlign: "center",
      marginRight: "10px",
    }}
  >
                              <img
                                width="45px"
                                src={process.env.PUBLIC_URL + '/UPI.png'}
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center flex-column ml-4">
                              <div className="text-left">
                                <strong style={{ fontSize: "1.1rem", color: "#fff" }}>Withdraw through UPI</strong>
                                <br />

                              </div>
                              <div className="jss31"></div>
                            </div>
                            <button onClick={() => { setNext(false) }} className="btn btn-danger text-white font-weight-bold  ml-auto mr-3" style={{ fontSize: '0.5rem' }}>Edit</button>
                          </div>
                        </div>}

                        { Boolean(isDecentroPayoutActive) && Boolean(type == "banktransfer") && <div className="add-fund-box my-3" style={{ paddingTop: "0px", height: "60px" }}>
<div
  className="d-flex align-items-center"
  style={{
    backgroundColor: "#333",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "5px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  }}
>
  <div
    className="d-flex align-items-center"
    style={{
      height: "60px",
      textAlign: "center",
      marginRight: "10px",
    }}
  >
    <img
      width="35px"
      src={process.env.PUBLIC_URL + "/Bank.png"}
      alt=""
      style={{
        marginLeft: "7px",
        paddingBottom: "10px",
        paddingLeft: "3px",
        paddingTop: "5px",
      }}
    />
  </div>
  <div className="d-flex justify-content-center flex-column ml-4">
    <div className="text-left">
      <strong style={{ fontSize: "1.1rem", color: "#fff" }}>IMPS/Bank Transfer</strong>
      <br />
    
    </div>
                              <div className="jss31"></div>
                            </div>
                            <button onClick={() => { setType(undefined); setNext(false); console.log(type) }} className="btn btn-danger text-white font-weight-bold ml-auto mr-3" style={{ fontSize: '0.5rem' }}>Edit</button>
                          </div>
                        </div>}
                        <div className="">
                          {Boolean(isDecentroPayoutActive) && type == "banktransfer" && <div><div >
                       <br /> 
  {/* Account Holder Name */}
  <label htmlFor="holder_name" className="form-label">
    Account Holder Name
  </label>
  <div className="input-container">
    <input
      type="text"
      className="custom-input"
      id="holder_name"
      placeholder="Enter Account Holder Name"
      value={holder_name}
      onChange={(e) => setHolder_name(e.target.value)}
      required
    />
  </div>

  {/* Account Number */}
  <label htmlFor="account_number" className="form-label">
    Account Number
  </label>
  <div className="input-container">
    <input
      type="number"
      inputMode="numeric"
      pattern="[0-9]*"
      className="custom-input"
      id="account_number"
      placeholder="Enter Account Number"
      value={account_number}
      onChange={(e) => setAccount_number(e.target.value)}
      required
    />
  </div>

  {/* IFSC Code */}
  <label htmlFor="ifsc_code" className="form-label">
    IFSC Code
  </label>
  <div className="input-container">
    <input
      type="text"
      className="custom-input"
      id="ifsc_code"
      placeholder="Enter IFSC Code"
      value={ifsc_code}
      onChange={(e) => setIfsc_code(e.target.value)}
      required
    />
  </div>
</div>
</div>}
                          {Boolean(isManualPayoutActive) && type == "manualupi" && <div >
                          
                          <div >
                            <label htmlFor="username " className="mr-5">
                              <i className="far fa-bank mr-2"></i>Account holder name
                            </label>
                            <div className="col-12 mb-3 p-0">
                              <input
                                type="text"
                                className="form-control"
                                id="account_no"
                                placeholder="Enter Account Name"
                                name="ifsc"
                                value={holder_name}
                                onChange={(e) => setHolder_name(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          
                            <label htmlFor="username " className="mr-5">
                              <i className="far fa-bank mr-2"></i>UPI ID
                            </label>
                            <div className="col-12  p-0">
                              <input
                                type="text"
                                className="form-control"
                                id="account_no"
                                placeholder="Enter UPI ID (9999999999@xyz)"
                                name="ifsc"
                                value={upi_id}
                                onChange={(e) => setUpi_id(e.target.value)}
                              />
                            </div>
                            
                            <small className="text-danger">
                            कृपया सही UPI आईडी (99999999@xyz) दर्ज करें।*
                            </small>
                          </div>}
                          
                          
                          {Boolean(isRazorPayPayoutActive) && type == "upi" && <div >
      <br />                    
<div className="form-container">
  {/* Account Holder Name */}
  <div className="input-container">
    <label htmlFor="holder_name" className="form-label">
      Account Holder Name
    </label>
    <input
      type="text"
      className="custom-input"
      id="holder_name"
      placeholder="Enter Account Name"
      value={holder_name}
      onChange={(e) => setHolder_name(e.target.value)}
      required
    />
  </div>

  {/* UPI ID */}
  <div className="input-container">
    <label htmlFor="upi_id" className="form-label">
      UPI ID
    </label>
    <input
      type="text"
      className="custom-input"
      id="upi_id"
      placeholder="Enter UPI ID (9999999999@xyz)"
      value={upi_id}
      onChange={(e) => setUpi_id(e.target.value)}
      required
    />
  </div>
</div>        
</div> }
                        </div>
                      </div>

                      {/* bank details end */}
<div className="form-container">
  {/* Amount Input */}
  <div className="input-container">
    <label htmlFor="amount" className="form-label">
      Amount
    </label>
    <input
      type="tel"
      className="custom-input"
      name="amount"
      placeholder="Enter Amount"
      onChange={(e) => setAmount(e.target.value)}
      required
    />
  </div>
</div>
                      <div className="col-12 p-0 mt-1 pt-3">
<button 
    type="button" 
    className="btn-block btn-sm" 
    style={{ 
        height: "40px", 
        backgroundColor: "blue", // New color for the button
        color: "white", 
        borderRadius: "5px", // Adjust border radius for a softer look
        fontSize: "16px", // Adjust font size for better visibility
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Add a subtle shadow for depth
        border: "none", // Remove the border
        transition: "background-color 0.3s ease, transform 0.3s ease" // Add transition for hover effects
    }} 
    disabled={!submitBtn} 
    onClick={() => updateBankDetails()}
    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "blue"} // Darker shade on hover
    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "grey"} // Original color on mouse leave
>
   <b> Withdraw
</b></button>
                        {Boolean(submitBtn)?'':'Reload Page'}
                      </div> 

    
                    </div>

                  </div>}
                </div>}
{user && user.verified === 'unverified' && (
  <div style={{ border: "2px solid red", borderRadius: "10px", padding: "20px", display: "flex", alignItems: "center", height: "100px" }}>
    <picture style={{ marginRight: "15px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="red">
        <path d="M12 2c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm1 14h-2v-2h2v2zm0-4h-2v-6h2v6z"/>
      </svg>
    </picture>
    <div style={{ flex: 1 }}>
      <div style={{ fontWeight: "bold", color: "#333" }}>KYC is not complete</div>
      <div style={{ color: "#666" }}>Complete KYC to take Withdrawals</div>
    </div>
    <Link to="/Kyc2" style={{ textDecoration: 'none' }}>
      <button
        className="btn btn-danger btn-sm text-capitalize"
        style={{ color: 'white', border: 'none' }}
      >  <span className="font-9" style={{ color: 'white' }}>
        <b>Complete KYC Now</b> </span>
      </button>
    </Link>
  </div>
)}

{user && user.verified === 'pending' && (
  <div style={{ border: "2px solid orange", borderRadius: "10px", padding: "20px", display: "flex", alignItems: "center", height: "100px" }}>
    <picture style={{ marginRight: "15px" }}>
       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="20" height="20" fill="orange">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.9190l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                </svg>
    </picture>
    <div style={{ flex: 1 }}>
      <div style={{ fontWeight: "bold", color: "#333" }}>Your KYC is not complete</div>
      <div style={{ color: "#666" }}>Please wait, your KYC is under process</div>
    </div>
  </div>
)}

              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}
export default Withdrawopt