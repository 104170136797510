import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS Include
import "animate.css"; // Animation Library

const challenges = [10, 30, 50, 100, 150, 200, 250, 300, 450, 500];

const MyComponent = () => {
  const [loading, setLoading] = useState(null);

  const handleClick = (index) => {
    setLoading(index);
    toast.info("Web Mode is Under Maintenance", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });

    setTimeout(() => {
      setLoading(null);
    }, 2000);
  };

  return (
      
     <div className="leftContainer p-4" style={{ minHeight: "100vh", height: "100%" }}>
      <ToastContainer />
      <h2 className="text-center text-dark mb-4 ">
        Available Challenges
      </h2>

      {/* Challenges Cards */}
      <div className="row">
        {challenges.map((amount, index) => {
          const winAmount = amount * 1.95;

          return (
            <div key={index} className="col-12 mb-3 animate__animated animate__slideInLeft">
              <div
                className="card shadow-sm border-0 rounded-lg"
                style={{
                  transition: "0.3s",
                  background: "#ffffff",
                  borderLeft: "5px solid #28a745",
                  padding: "0",
                }}
              >
                {/* Card Header */}
                <div
                  className="card-header d-flex justify-content-between align-items-center"
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    backgroundColor: "#E8ECEF",
                    padding: "6px 12px",
                    color: "#000",
                  }}
                >
                  <span>Current Playing 0</span>
                  <span style={{ fontWeight: "bold" }}>Entry Fee: ₹{amount}</span>
                </div>

                <div className="card-body d-flex justify-content-between align-items-center p-3">
                  {/* Left Section - Prize Pool with Icons */}
                  <div>
                    <h6 className="text-muted mt-2 mb-1" style={{ fontSize: "12px" }}>
                      Prize Pool
                    </h6>
                    <h5 className="text-success fw-bold d-flex align-items-center" style={{ fontSize: "14px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trophy-fill me-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 2a1 1 0 0 0-1 1v2a3 3 0 0 0 3 3h.025A5.5 5.5 0 0 0 8 12.5V14H5v1h6v-1H8v-1.5A5.5 5.5 0 0 0 11.975 8H12a3 3 0 0 0 3-3V3a1 1 0 0 0-1-1h-1V.5a.5.5 0 0 0-1 0V2H4V.5a.5.5 0 0 0-1 0V2H3zM4 6a2 2 0 0 1-2-2V3h2v3zm10-2a2 2 0 0 1-2 2V3h2v1z" />
                      </svg>
                      ₹{winAmount.toFixed(2)}
                    </h5>
                  </div>

                  {/* Right Section - Join Button */}
                  <button
  onClick={() => handleClick(index)}
  className="btn fw-bold px-3 d-flex align-items-center"
  disabled={loading === index}
  style={{
    fontSize: "13px",
    transition: "0.3s",
    backgroundColor: "#0D6EFD",
    color: "white",
    border: "none",
  }}
>
  {loading === index ? (
    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  ) : (
    <span style={{ color: '#fff', fontWeight: 'bold' }}>
                    <b>PLAY</b></span>
  )}
</button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyComponent;