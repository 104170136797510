import React, { useState } from "react";
import styles from "../css/notification.module.css";

const Notification = () => {
  const [showCanvas, setShowCanvas] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleShowCanvas = () => {
    setShowCanvas(true);
  };

  const handleCloseCanvas = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShowCanvas(false);
      setIsClosing(false);
    }, 300);
  };

  return (
    <div className={styles.notificationContainer}>
      <div className={styles.notificationCard}>
        <div className={styles.notificationBar}></div>
        <div className={styles.notificationContent}>
          <img
            src={process.env.PUBLIC_URL + "/Images/nonotification.png"}
            alt="No Notification"
            className={styles.notificationImg}
          />
          <h5 className={styles.notificationTitle}>No notifications yet!</h5>
          <p className={styles.notificationDescription}>
            Seems like you haven’t done any activity yet.
          </p>

          {/* Button Container for better spacing */}
          <div className={styles.buttonsContainer}>
            {/* How to Play Button */}
            <button
              className={`${styles.notificationButtons} ${styles.playButtons}`}
              onClick={handleShowCanvas}
            >
              🎮 How to Play
            </button>

            {/* Cash Up to 500 Button */}
            <a
              href="https://t.sliceit.com/s?c=lKrYbfJ&ic=AADIZ53195"
              target="_blank"
              rel="noopener noreferrer"
              className={`${styles.notificationButtons} ${styles.cashButtons}`}
            >
              💰 Cash Up to 500
            </a>
          </div>
        </div>
      </div>

      {showCanvas && (
        <div className={styles.offcanvasBackdrop} onClick={handleCloseCanvas}>
          <div
            className={`${styles.offcanvas} ${isClosing ? styles.slideOut : styles.slideIn}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.offcanvasHeader}>
              <h5>How to Play</h5>
              <button className={styles.offcanvasClose} onClick={handleCloseCanvas}>
                ×
              </button>
            </div>
            <iframe
              src="https://www.youtube.com/embed/N4tUSsOih7M"
              title="How to Play"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notification;