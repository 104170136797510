// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/Images/global-bg-rays.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leftContainer{
    min-height: 100%;
    position: relative;
}
.headerContainer, .leftContainer {
    background-color: #f9f9f9 !important;
    max-width: 480px;
    width: 100%;
   
}
.rightContainer {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-color: #fff;
    background-size: cover;
    overflow: hidden;
    right: 0;
    z-index: 4;
}
.rightContainer {
    bottom: 0;
    left: 480px;
    position: fixed;
    top: 0;
    border-left: 10px solid #e0e0e0;
}
#root{
    height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/app2.js/css/layout.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,oCAAoC;IACpC,gBAAgB;IAChB,WAAW;;AAEf;AACA;IACI,mDAA0D;IAC1D,sBAAsB;IACtB,sBAAsB;IACtB,gBAAgB;IAChB,QAAQ;IACR,UAAU;AACd;AACA;IACI,SAAS;IACT,WAAW;IACX,eAAe;IACf,MAAM;IACN,+BAA+B;AACnC;AACA;IACI,aAAa;AACjB","sourcesContent":[".leftContainer{\n    min-height: 100%;\n    position: relative;\n}\n.headerContainer, .leftContainer {\n    background-color: #f9f9f9 !important;\n    max-width: 480px;\n    width: 100%;\n   \n}\n.rightContainer {\n    background: url(../../../public/Images/global-bg-rays.svg);\n    background-color: #fff;\n    background-size: cover;\n    overflow: hidden;\n    right: 0;\n    z-index: 4;\n}\n.rightContainer {\n    bottom: 0;\n    left: 480px;\n    position: fixed;\n    top: 0;\n    border-left: 10px solid #e0e0e0;\n}\n#root{\n    height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
