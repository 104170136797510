import React, { useEffect, useState } from 'react';
import css from '../css/Mywallet.module.css';
import Rightcontainer from '../Components/Rightcontainer';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

const Mywallet = () => {
  const history = useHistory();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  let access_token = localStorage.getItem("token");
  const [user, setUser] = useState();

  useEffect(() => {
    access_token = localStorage.getItem('token');
    if (!access_token) {
      history.push("/login");
      window.location.reload();
    }
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    axios.get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
      }).catch((e) => {
        console.log(e);
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          window.location.reload();
          history.push("/login");
        }
      });
  }, [history, baseUrl]);

  return (
    <>
      <div className="leftContainer">
        <div className="container px-3 mt-4 py-5" style={{ height: "12px" }}>
    <div className="mb-3 d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center justify-content-start">
        <button onClick={() => history.goBack()} className="btn btn-dark" style={{ backgroundColor: '#333', borderRadius: '5px' }}>
          <i className="fa fa-arrow-circle-left" style={{ color: 'white' }}></i>
          <span className="text-capitalize fw-bold" style={{ color: 'white' }}><b>BACK</b></span> 
        </button>
      </div>
      <Link 
        className="text-capitalize btn btn-outline-primary fw-bold" 
        to="/transaction-history" 
        style={{ color: '#333', borderColor: '#333', height: '31px', borderRadius: '5px' }}> 
        <span className="font-9" style={{ color: '#333' }}><b>Wallet history</b></span>
      </Link>
    </div>
  </div>

        {/* Deposit Chips Section */}
        <div className="card mt-1" style={{
          border: '1px solid rgb(128, 128, 128)',
          width: '93%',
          margin: '0 auto',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)', // dark shadow
          backgroundColor: '#ffffff' // white background
        }}>
          <div className="card-header text-center" style={{
            fontWeight: 'bold',
            fontSize: '14px',
            letterSpacing: '0.9px',
            backgroundColor: '#878787', // light gray
            padding: '8px 16px',
            textAlign: 'center',
          }}>
            Deposit Chips
          </div>

          <div className="card-body">
            <div role="alert" className="fade alert alert-secondary show" style={{ fontSize: "0.8rem", backgroundColor: "#e0e0e0", color: "#000000" }}>
              These chips are won from Spin & Win or purchased, they can only be used to play games. They cannot be withdrawn to Bank or UPI.
            </div>
            <div className="d-flex align-items-center justify-content-center px-2">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <span className="text-capitalize fw-bold" style={{ fontSize: "1rem", fontWeight: "bold", color: "#000000" }}>
                  Chips
                </span>
                <span className="fs-3 fw-bold" style={{ fontSize: "1rem", fontWeight: "bold", color: "#000000" }}>
                  ₹{user && user.Wallet_balance !== undefined ? user.Wallet_balance.toFixed(0) : "0"}
                </span>
              </div>
            </div>
            <div className="d-flex flex-column align-items-stretch pt-4">
              <Link to="/addcase">
                <button
                  className={`${css.walletCard_btn} btn btn-secondary w-100 fw-bold text-uppercase`}
                  style={{
                    backgroundColor: '#333',
                    color: '#FFFFFF',
                    border: '5px solid #333',
                    borderRadius: '5px',
                    padding: '10px 20px',
                  }}
                >
                  <span className="text-capitalize fw-bold" style={{ color: "white" }}><b>Add Cash</b></span>
                </button>
              </Link>
            </div>
          </div>
        </div>

        {/* Winning Chips Section */}
        <div className={css.wallet_card2}>
          <div className="card mt-3" style={{
            border: '1px solid rgb(128, 128, 128)',
            width: '93%',
            margin: '0 auto',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)', // dark shadow
            backgroundColor: '#ffffff' // white background
          }}>
            <div className="card-header text-center" style={{
              fontWeight: 'bold',
              fontSize: '14px',
              letterSpacing: '0.9px',
              backgroundColor: '#878787', // light gray
              padding: '8px 16px',
              textAlign: 'center',
            }}>
              Winning Chips
            </div>
            <div className="card-body">
              <div>
                <div>
                  <div role="alert" className="fade alert alert-secondary show" style={{ fontSize: "0.8rem", backgroundColor: "#e0e0e0", color: "#000000" }}>
                    These chips are won from games and referrals. They can be withdrawn to Bank or UPI, or used to play games.
                  </div>
                  <div className="d-flex align-items-center justify-content-center px-2">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <span className="text-capitalize fw-bold" style={{ fontSize: "1rem", fontWeight: "bold", color: "#000000" }}>
                        Chips
                      </span>
                      <span className="fs-4" style={{ fontSize: "1rem", fontWeight: "bold", color: "#000000" }}>
                        ₹{user && user.withdrawAmount !== undefined ? user.withdrawAmount.toFixed(0) : "0"}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex gap-2 flex-column align-items-stretch pt-4 pb-3">
                    <Link className="text-decoration-none" to="/Withdrawopt">
                      <div className="d-grid gap-2">
                        <button
                          className="btn btn-secondary w-100 fw-bold text-uppercase"
                                          style={{
                    backgroundColor: '#333',
                    color: '#FFFFFF',
                    border: '5px solid #333',
                    borderRadius: '5px',
                    padding: '10px 20px',
                  }}
                >                          <span className="text-capitalize fw-bold" style={{ color: "white" }}><b>Withdraw</b></span>
                        </button>
                      </div>
                    </Link>
                    <div className="mt-2">
                      <div className="text-decoration-none">
                        <div className="d-grid align-items-center gap-2">
                        <button
  className="btn convert-to-deposit w-100 fw-bold text-uppercase"
  style={{
    backgroundColor: '#188754', // keep original color
    color: '#FFFFFF',
    border: '5px solid #188754',
    borderRadius: '5px',
    padding: '15px 20px', // Increased top and bottom padding
    position: 'relative',
  }}
>
  <span className="text-capitalize fw-bold" style={{ color: "white" }}>
    <b>Convert to Deposit</b>
  </span>
  <small
    className="bg-white px-2 py-1 rounded-5"
    style={{
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      fontWeight: 'bold',
      borderRadius: '10px',
      color: '#188754',
    }}
  >
    <b>2% Bonus!</b>
  </small>
</button>                            
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "15px" }}>
          <ul className="list-group"></ul>
        </div>
      </div>
    </div>
  </div>

  {/* Right Container */}
  <div className="rightContainer">
    <Rightcontainer />
  </div>
</div>
          </>
      )
}
export default Mywallet;