import React, { useEffect, useState } from 'react';
import './Component-css/Downloadbutton.css';

const Downloadbutton = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [isInstalling, setIsInstalling] = useState(false);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, []);

  const onClick = async (evt) => {
    evt.preventDefault();
    if (!promptInstall) return;

    setIsInstalling(true); // Start the spinner
    promptInstall.prompt();

    const { outcome } = await promptInstall.userChoice;
    if (outcome === 'accepted') {
      console.log('User accepted the install prompt');
    } else {
      console.log('User dismissed the install prompt');
    }

    setIsInstalling(false); // Stop the spinner
    setSupportsPWA(false); // Hide the button after installation
  };

  if (!supportsPWA) return null;

  return (
    <div
      className="my-stickey-footer"
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        maxWidth: 480,
        backgroundColor: 'grey',
      }}
    >
      <div className="text-center" style={{ zoom: '1.2', padding: '10px 0px' }}>
        <button
          onClick={onClick}
          className={`btn btn-success btn-sm ${isInstalling ? 'spinning' : ''}`}
          style={{ fontWeight: 500 }}
          disabled={isInstalling}
        >
          {isInstalling ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          ) : (
            <>
              <img
                src="https://Ludopay.in/Images/android.png"
                alt=""
                style={{ marginRight: 10 }}
                width="15px"
              />
             <span className="text-center" style={{ color: '#fff', fontWeight: 'bold' }}>
    DOWNLOAD OUR APP
</span>

              <img
                src="https://Ludopay.in/Images/dowloadIcon.png"
                alt=""
                style={{ marginLeft: 10 }}
                width="13px"
              />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default Downloadbutton;
