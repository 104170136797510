// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
 margin: 0;
 padding: 0;
 height:100%;
}
.App{
 color: #228b22;
 text-align: center;
}
.footer {

 position: fixed;
 width: 100%;
 bottom: 0;
 color: white;
 font-size: 25px;
}
.my-sticky-footer {
  text-align: center;
 background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important;
  color: #fff;
  padding: 20px; 
  position: fixed;
  bottom: 0;
  width: 100%;
}
.spinning {
  cursor: not-allowed;
  opacity: 0.7;
}

.my-stickey-footer .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/app2.js/Components/Component-css/Downloadbutton.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT,UAAU;CACV,WAAW;AACZ;AACA;CACC,cAAc;CACd,kBAAkB;AACnB;AACA;;CAEC,eAAe;CACf,WAAW;CACX,SAAS;CACT,YAAY;CACZ,eAAe;AAChB;AACA;EACE,kBAAkB;CACnB,iFAAiF;EAChF,WAAW;EACX,aAAa;EACb,eAAe;EACf,SAAS;EACT,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":["body {\n margin: 0;\n padding: 0;\n height:100%;\n}\n.App{\n color: #228b22;\n text-align: center;\n}\n.footer {\n\n position: fixed;\n width: 100%;\n bottom: 0;\n color: white;\n font-size: 25px;\n}\n.my-sticky-footer {\n  text-align: center;\n background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important;\n  color: #fff;\n  padding: 20px; \n  position: fixed;\n  bottom: 0;\n  width: 100%;\n}\n.spinning {\n  cursor: not-allowed;\n  opacity: 0.7;\n}\n\n.my-stickey-footer .btn {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
