import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap
import "animate.css"; // Animations

const tournaments = [
  { id: 1, entryFee: 0, type: "Free", prizePool: 1000 },
  { id: 2, entryFee: 50, type: "Paid" },
  { id: 3, entryFee: 100, type: "Paid" },
  { id: 4, entryFee: 200, type: "Paid" },
];

const TournamentPage = () => {
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [status, setStatus] = useState({});

  const handleJoin = (index) => {
    setLoadingIndex(index);
    setStatus((prev) => ({ ...prev, [index]: "Processing..." }));

    setTimeout(() => {
      toast.info("Tournament mode is under maintenance", {
        position: "bottom-right",
        autoClose: 3000,
        theme: "colored",
      });
      setStatus((prev) => ({ ...prev, [index]: "Registered" }));
      setLoadingIndex(null);
    }, 2000);
  };

  return (
    <div className="leftContainer p-4" style={{ minHeight: "100vh", height: "100%" }}>
      <ToastContainer />
      <h2 className="text-center text-dark mb-4 animate__animated animate__fadeInDown">
        🎮 Ongoing Tournaments
      </h2>

      <div className="row">
        {tournaments.map(({ id, entryFee, type, prizePool }, index) => {
          const isFree = entryFee === 0;
          const calculatedPrizePool = Math.floor((entryFee * 4) * 0.97);
          const tournamentStatus = status[index] || "Register Now";

          return (
            <div key={id} className="col-md-6 mb-4">
              <div
  className="card shadow-lg border-0 rounded-lg animate__animated animate__slideInLeft"
  style={{
    borderLeft: `6px solid ${isFree ? "#ffcc00" : "#28a745"}`,
    background: "#f8f9fa",
  }}
>
                {/* Header */}
                <div
                  className="card-header d-flex justify-content-between align-items-center"
                  style={{
                    background: isFree
                      ? "linear-gradient(45deg, #ffcc00, #ff9900)"
                      : "linear-gradient(45deg, #28a745, #1e7e34)",
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                >
                  <span>{isFree ? "🎟 Free Tournament" : `Tournament #${id}`}</span>
                  <span className="badge bg-dark">{isFree ? "₹1000 Prize" : "Paid Entry"}</span>
                </div>

                {/* Card Body */}
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="text-muted mb-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-cash-stack me-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 4h8v2H4V4Z" />
                          <path
                            fillRule="evenodd"
                            d="M3 2h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1Zm0 1v7h10V3H3Z"
                          />
                          <path d="M5 8h6v2H5V8Z" />
                        </svg>
                        Entry Fee
                      </h6>
                      <h5 className="text-dark fw-bold">{isFree ? "FREE" : `₹${entryFee}`}</h5>

                      <h6 className="text-muted mt-2 mb-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trophy-fill me-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 1h5a1 1 0 0 1 1 1v2.757a6.996 6.996 0 0 1 1.79 2.536c.348.767.587 1.615.693 2.472a.5.5 0 0 1-.486.535H2.503a.5.5 0 0 1-.487-.535c.106-.857.345-1.705.693-2.472A6.996 6.996 0 0 1 4.5 4.757V2a1 1 0 0 1 1-1Z" />
                          <path d="M2.5 9.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5 7 7 0 1 1-11 0Z" />
                        </svg>
                        Prize Pool
                      </h6>
                      <h5 className="text-success fw-bold">
                        ₹{isFree ? prizePool : calculatedPrizePool}
                      </h5>
                    </div>

                    {/* Right Section - Button */}
                    <div className="text-center">
                      <h6 className="text-muted">Tournament Status</h6>
                      <h5 className="fw-bold text-primary">{tournamentStatus}</h5>

                      <button
                        onClick={() => handleJoin(index)}
                        className="btn btn-success fw-bold mt-3"
                        style={{ width: "100%", fontSize: "14px", padding: "8px", borderRadius: "8px" }}
                        disabled={loadingIndex === index}
                      >
                        {loadingIndex === index ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Join Now"
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                {/* Footer - Updated Status */}
                <div
                  className="card-footer text-center"
                  style={{
                    background: tournamentStatus === "Processing..." ? "#dc3545" : "#007bff",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "14px",
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  }}
                >
                  {tournamentStatus === "Processing..." ? "Joining..." : "Slots Available"}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TournamentPage;