// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification_games_section_headline__FjZFt { 
    color: rgb(149, 149, 149); 
    font-size: 0.75em; 
    font-weight: 400; 
    line-height: 18px;
} 

.notification_games_section_title__ghV4l { 
    color: rgb(44, 44, 44); 
    font-size: 1em; 
    font-weight: 700;
} 
`, "",{"version":3,"sources":["webpack://./src/app2.js/css/notification.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".games_section_headline { \n    color: rgb(149, 149, 149); \n    font-size: 0.75em; \n    font-weight: 400; \n    line-height: 18px;\n} \n\n.games_section_title { \n    color: rgb(44, 44, 44); \n    font-size: 1em; \n    font-weight: 700;\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"games_section_headline": `notification_games_section_headline__FjZFt`,
	"games_section_title": `notification_games_section_title__ghV4l`
};
export default ___CSS_LOADER_EXPORT___;
