import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const Transactionhistory = () => {
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;

  const [user, setUser] = useState();
  const limit = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [cardData, setCardData] = useState([]);
  const [showWithdrawalHistory, setShowWithdrawalHistory] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  useEffect(() => {
    role();
  }, [pageNumber, limit, showWithdrawalHistory]);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const role = async () => {
    setLoading(true);
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    try {
      const res = await axios.get(`${baseUrl}me`, { headers });
      setUser(res.data);
      if (showWithdrawalHistory) {
        fetchWithdrawalHistory(res.data._id);
      } else {
        fetchDepositHistory(res.data._id);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchDepositHistory = async (id) => {
    setLoading(true);
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    try {
      const res = await axios.get(`${baseUrl}temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`, { headers });
      setCardData(res.data.ress);
      setNumberOfPages(res.data.totalPages);
    } finally {
      setLoading(false);
    }
  };

  const fetchWithdrawalHistory = async (id) => {
    setLoading(true);
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    try {
      const res = await axios.get(`${baseUrl}temp/withdrawal/${id}?page=${pageNumber}&_limit=${limit}`, { headers });
      setCardData(res.data.ress);
      setNumberOfPages(res.data.totalPages);
    } finally {
      setLoading(false);
    }
  };

  const toggleHistoryType = () => {
    setShowWithdrawalHistory(!showWithdrawalHistory);
  };

  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("default", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
  };

  const checkupigatewaypay = (order_id, order_token, pay_date) => {
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    axios
      .post(`${baseUrl}depositupipay/response`, { order_id, order_token }, { headers })
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title = res.data.status === "PAID" ? "Deposit submitted successfully" : "Transaction Failed";
        role();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          window.location.reload();
        }
      });
  };

  const handlePageChange = (newPage) => {
    setPageNumber(newPage);
  };

  return (
    <div className="leftContainer" style={{ minHeight: '100vh', paddingBottom: '30px' }}>
      <ToastContainer />
      <div className="leftContainer" style={{ minHeight: "100vh", height: "100%" }}>
        <div className="pt-5 mb-3"></div>
        <div className="card mt-2 p-2" style={{ backgroundColor: "#333333", borderRadius: "1px", padding: "10px", margin: "0 auto" }}>
          <div className="d-flex justify-content-between">
            <Link to="/Gamehistory" className={css.Link}>
              <span className="font-9" style={{ fontWeight: '500', color: 'black' }}>Game</span>
            </Link>
            <Link to="/transaction-history" className={css.Linked}>
              <span className="font-9" style={{ fontWeight: '500', color: 'white' }}>Wallet</span>
            </Link>
            <Link to="/Referral-history" className={css.Link}>
              <span className="font-9" style={{ fontWeight: '500', color: 'black' }}>Referral</span>
            </Link>
          </div> 
        </div>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh", backgroundColor: "#f9f9f9" }}>
            <div className="spinner-border" role="status" style={{ width: "3rem", height: "3rem", borderWidth: "0.4rem", borderColor: "blue", borderTopColor: "transparent" }}>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : cardData && cardData.length > 0 ? (
          cardData
            .filter((card) => !(card.Req_type === "deposit" && card.status === "Pending"))
            .map((card) => {
              const { _id, createdAt, Req_type, status, Withdraw_type, amount, txn_msg, closing_balance } = card;

              const timestamp = _id.toString().slice(0, 8);
              const counterid = parseInt(timestamp, 16);
              const dateObj = new Date(createdAt);
              const dateStr = dateObj.toLocaleDateString("en-GB");
              const timeStr = dateObj.toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              });

              let titleMsg = "";
              let failedReason = "";

              const statusColors = {
                failed: "#FF4500",
                success: "#228B22",
                paid: "#32CD32",
                "IN PROCESS": "#FFA500",
                penalty: "#FF8C00",
                bonus: "#1E90FF",
              };

              let statusLabel = status;
              if (status === "Pending" && Req_type === "withdraw") {
                statusLabel = "IN PROCESS";
              }

              if (Req_type === "deposit" || Req_type === "bonus") {
                titleMsg = "Cash Added";
              } else if (Req_type === "withdraw") {
                titleMsg = `Withdraw via ${Withdraw_type}`;
                if (status === "failed") {
                  failedReason = "Reason: Invalid UPI ID or Bank Details";
                }
              } else if (Req_type === "penalty") {
                titleMsg = "Penalty Applied";
              } else {
                titleMsg = "Unknown Request";
              }

              return (
                <div key={_id} style={{ backgroundColor: "#fff", borderRadius: "6px", border: "1px solid #ddd", boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)", margin: "6px 0", padding: "8px 10px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", maxWidth: "500px", width: "100%" }}>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: "bold", fontSize: "0.85rem", marginBottom: "4px", color: "#333" }}>{titleMsg}</div>
                    <div style={{ fontSize: "0.6rem", color: "#555", marginBottom: "4px" }}>Order ID: JP-{counterid}</div>
                    <div style={{ fontSize: "0.7rem", color: "#777" }}>
                      <span style={{ marginRight: "6px" }}>{timeStr}</span>
                      <span>{dateStr}</span>
                    </div>
                    {failedReason && <div style={{ fontSize: "0.7rem", color: "red", marginTop: "4px" }}>{failedReason}</div>}
                    {txn_msg && <div style={{ fontSize: "0.7rem", color: "#444", marginTop: "4px" }}>{txn_msg}</div>}
                  </div>

                  <div style={{ textAlign: "right", minWidth: "100px" }}>
                    <div style={{ padding: "3px 6px", backgroundColor: statusColors[statusLabel.toLowerCase()] || "#999", color: "#fff", borderRadius: "4px", fontSize: "0.7rem", fontWeight: "bold", textAlign: "left", marginBottom: "6px" }}>
                      {statusLabel}
                    </div>
                    <div style={{ fontSize: "0.85rem", fontWeight: "bold", textAlign: "right" }}>
                      {Req_type === "withdraw" ? (
                        <img src="https://i.postimg.cc/R0j1NZNQ/Minusicon.png" alt="(-)" width="10" height="10" style={{ marginRight: "5px" }} />
                      ) : (
                        <img src="https://i.postimg.cc/rmFVG8B7/Plusicon.png" alt="(+)" width="10" height="10" style={{ marginRight: "5px" }} />
                      )}
                      ₹{amount}
                    </div>
                    {closing_balance && (
                      <div style={{ fontSize: "0.6rem", fontWeight: "none", color: "#666", marginTop: "4px", textAlign: "right" }}>
                        Closing balance: ₹{closing_balance.toFixed(1)}
                      </div>
                    )}
                  </div>
                </div>
              );
            })
        ) : (
          <div className="text-center" 
            style={{ 
              backgroundColor: '#f9f9f9',
              borderRadius: '10px',
              padding: '20px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)', 
              color: '#333',
            }}>
            <img
              src={process.env.PUBLIC_URL + '/Images/notransactionhistory.png'}
              alt="no data"
              width={250}
              height={250}
              className="img-fluid"
              style={{ marginTop: "20%" }}
            />
            <div className="mt-2">
              <h3 className="font-weight-bold" style={{ color: '#333' }}>No Transaction History</h3>
              <p className="text-muted">You have not made any Transaction yet.</p>
            </div> 
          </div>
        )}
<div role="group" className="btn-group" style={{
  marginTop: '5px',
  marginBottom: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  //background: 'rgba(0, 123, 255, 0.7)',
  borderRadius: '8px',
  padding: '6px 10px',
  width: '100%',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
  color: 'white',
}}>

  <button 
    type="button" 
    disabled={pageNumber === 0} 
    className="btn btn-primary" 
    style={{
  backgroundColor: '#0056b3',
  color: 'white',
  border: 'none',
  borderTopLeftRadius: '5px',
  borderBottomLeftRadius: '5px',
  padding: '6px 10px',
  fontSize: '14px',
  cursor: 'pointer',
  fontWeight: 'bold',
  marginRight: '10px',
  transition: 'all 0.3s ease',
  borderRight: '2px solid white',  // Added white right border
}}
    onClick={() => handlePageChange(pageNumber - 1)}
    onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
    onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
  >
    <span style={{ color: '#fff', fontWeight: 'bold' }}>
      <b>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor">
          <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor">
          <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
        </svg>
      </b>
    </span>
  </button> 

  {[pageNumber - 1, pageNumber, pageNumber + 1].map(page => (
    page > 0 && page <= numberOfPages && (
      <button 
        key={page} 
        type="button" 
        className="btn btn-primary" 
        style={{
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          //borderRadius: '6px',
          padding: '6px 10px',
          fontSize: '14px',
          cursor: 'pointer',
          fontWeight: 'bold',
          margin: '0 10px',
          transition: 'all 0.3s ease',
        }} 
        onClick={() => handlePageChange(page)}
        onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
        onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
      >
        <b><span style={{ color: '#fff', fontWeight: 'bold' }}>{page + 1}</span></b>
      </button>
    )
  ))}
  
  <button 
    type="button" 
    disabled={pageNumber === numberOfPages - 1} 
    className="btn btn-primary" 
   style={{
  backgroundColor: '#0056b3',
  color: 'white',
  border: 'none',
  borderTopRightRadius: '5px',
  borderBottomRightRadius: '5px',
  padding: '6px 10px',
  fontSize: '14px',
  cursor: 'pointer',
  fontWeight: 'bold',
  marginLeft: '10px',
  transition: 'all 0.3s ease',
  borderLeft: '2px solid white',  // Added white left border
}}
    onClick={() => handlePageChange(pageNumber + 1)}
    onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
    onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
  >
    <span style={{ color: '#fff', fontWeight: 'bold' }}>
      <b>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor">
          <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor">
          <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
        </svg>
      </b>
    </span>
  </button>
</div>
      </div>
    </div>
  );
};

export default Transactionhistory; 
