import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import { Link } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import Rightcontainer from "../Components/Rightcontainer";
import axios from "axios";

const Gamehistory = () => {
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  let baseUrl;
 
  if (nodeMode === "development") {
    baseUrl = backendLocalApiUrl;
  } else {
    baseUrl = backendLiveApiUrl;
  }

  const [user, setUser] = useState(null);
  const [limit] = useState(50);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [roomCodes, setRoomCodes] = useState({});
  const [game, setGame] = useState([]);
  
  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await axios.get(`${baseUrl}me`, { headers });
      setUser(res.data);
      Allgames(res.data._id, pageNumber, limit);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
  };

  const fetchRoomCode = async (gameId) => {
    const access_token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const response = await axios.get(`${baseUrl}game/roomcode/get/${gameId}`, { headers });
      setRoomCodes((prev) => ({ ...prev, [gameId]: response.data.Room_code }));
    } catch (error) {
      console.error(error);
    }
  };

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await axios.get(`${baseUrl}game/history/user/${id}?page=${pageNumber}&_limit=${limit}`, { headers });
      const filteredGames = res.data.data.filter(ele => ['completed', 'cancelled', 'conflict'].includes(ele.Status));
      setGame(filteredGames);
      setNumberOfPages(res.data.totalPages);
      filteredGames.forEach(ele => fetchRoomCode(ele._id));
    } catch (error) {
      console.error(error);
    }
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString('default', { month: 'short', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
    return newDate;
  };


  useEffect(() => {
    role();
    // eslint-disable-next-line
  }, [pageNumber, limit]);

  

  return (
<div>
  <div className="leftContainer" style={{minHeight:'100vh',height:'100%'}}>
    {/* pagination */}
    <div className="pt-5">
      <div className="mt-4">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </div>

{game && game.length > 0 ? game.map((card) => (
  <div
    key={card._id}
    className={`w-100 mt-2 py-1 d-flex align-items-center ${css.list_item}`}
    style={{
      backgroundColor: '#f5f5f5',  // Lighter background color
      borderRadius: '10px',
      border: '1px solid #ddd',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      color: '#333',  // Darker text color for contrast
    }}
  >
    {/* Game Details */}
    <div className={`mx-1 d-flex ${css.list_body}`}>
      <div className="d-flex align-items-center">
        <picture className="mr-2">
          <img
            height="32px"
            width="32px"
            src="https://i.postimg.cc/pdZ8NFf7/Lite.jpg"
            alt=""
            style={{ borderRadius: "5px" }}
          />
        </picture>
      </div>

      <div className="d-flex flex-column">
        <div style={{ fontSize: '0.85rem', fontWeight: 'bold' }}>
          {card.Status === "completed" 
            ? (card.Winner && card.Winner._id === user._id ? 'Win Against' : 'Lost Against') 
            : card.Status}
          &nbsp;  <b>{card.Accepetd_By && card.Accepetd_By._id === user._id ? ` ${card.Created_by.Name}` : card.Accepetd_By && ` ${card.Accepetd_By.Name}`}</b>.
        </div>

<div className={`${css.games_section_headline}`} style={{ fontSize: '12px' }}>
  ROOM CODE : <span style={{ color: 'grey' }}>{roomCodes[card._id] || 'N/A'}</span>
</div>
        {/* Move Date and Time below Battle ID */}
        <div style={{ fontSize: '0.75rem', color: '#999' }}>
          <div>{dateFormat(card.createdAt).split(',')[0]}
          {dateFormat(card.createdAt).split(',')[1]}
        </div></div>
      </div>
    </div>

                     {card.Status === 'completed' && (
              <div style={{ marginLeft: 'auto' }} className="right-0 d-flex align-items-end pr-3 flex-column">
                <div className="d-flex float-right font-8">
                  {card.Status === 'completed' && card.Winner?._id === user?._id && (
                    <>
                      <span className="text-success" style={{ fontSize: '0.8em', color: '#343a40' }}>
                        <picture className="ml-1 mb-1">
                          <img height="12px" width="12px" src="https://i.postimg.cc/rmFVG8B7/Plusicon.png" alt="" />
                        </picture>
                                    <img
              src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp"
              width="16px"
              alt=""
              style={{ marginRight: '1px' }}
            />

                        <b style={{ color: 'green' }}> {card.winnAmount}  </b>
                      </span>
                    </>
                  )}
                  {card.Status === 'completed' && card.Winner?._id !== user?._id && (
                    <>
                      <span className="text-danger" style={{ fontSize: '0.8em', color: '#343a40' }}>
                        <picture className="ml-1 mb-1">
                          <img height="12px" width="12px" src="https://i.postimg.cc/R0j1NZNQ/Minusicon.png" alt="" />
                        </picture>
                                 <img
              src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp"
              width="16px"
              alt=""
              style={{ marginRight: '1px' }}
            />
           <b> {card.Game_Ammount} </b>
                      </span>
                    </>
                  )}
                </div> 
<div
  className={`${css.games_section_headline}`}
  style={{
    fontSize: '0.6rem', // Reduced font size
    whiteSpace: 'nowrap',
    color: '#777',
  }}
>
  Closing balance: ₹{Math.floor(card.Winner._id === user._id ? card.Winner_closingbalance : card.Loser_closingbalance)}
</div>
      </div>
    )}
  </div>
)) : (
  <div className="text-center">
    <img
      src={process.env.PUBLIC_URL + '/Images/nogamehistory.png'}
      alt="no data"
      width={250}
      height={250}
      className="img-fluid"
      style={{ marginTop: "20%" }}
    />
    <div className="mt-2">
      <h3 className="font-weight-bold" style={{ color: '#333' }}>No Game History</h3>
      <p className="text-muted">You have no game history yet.</p>
    </div>
  </div>
)}
</div>
  <div className="rightContainer">
    <Rightcontainer />
  </div>
</div>
  );
}; 

export default Gamehistory;