import React, { useState } from 'react';
import css from '../css/leaderboard.module.css';

const Leaderboard = () => {
    const [activeTab, setActiveTab] = useState('game');

// Sample leaderboard data for Game and Refer top players
const gameTopPlayers = [
    { rank: 1, name: 'Sagar kumar' },
    { rank: 2, name: 'Sourav thakur' },
    { rank: 3, name: 'Nitesh meena' },
    { rank: 4, name: 'Sneha Gupta' },
    { rank: 5, name: 'Rajesh Singh' },
    { rank: 6, name: 'Suman Patel' },
    { rank: 7, name: 'Nitin Agarwal' },
    { rank: 8, name: 'Divya Joshi' },
    { rank: 9, name: 'Amit Mehta' },
    { rank: 10, name: 'Neha Yadav' },
    { rank: 11, name: 'Vikram Rathi' },
    { rank: 12, name: 'Kavita Sharma' },
    { rank: 13, name: 'Siddharth Yadav' },
    { rank: 14, name: 'Kiran Singh' },
    { rank: 15, name: 'Ravi Kumar' },
    { rank: 16, name: 'Nisha Rani' },
    { rank: 17, name: 'Manoj Thakur' },
    { rank: 18, name: 'Geeta Verma' },
    { rank: 19, name: 'Suresh Chaudhary' },
    { rank: 20, name: 'Tanvi Kapoor' },
    { rank: 21, name: 'Ajay Bhardwaj' },
    { rank: 22, name: 'Rita Sharma' },
    { rank: 23, name: 'Harish Kumar' },
    { rank: 24, name: 'Madhuri Singh' },
    { rank: 25, name: 'Kunal Rao' },
    { rank: 26, name: 'Sunita Agarwal' },
    { rank: 27, name: 'Ashok Patil' },
    { rank: 28, name: 'Tina Bansal' },
    { rank: 29, name: 'Ranjit Mehta' },
    { rank: 30, name: 'Shivani Verma' },
    { rank: 31, name: 'Pradeep Joshi' },
    { rank: 32, name: 'Neeraj Sharma' },
    { rank: 33, name: 'Aarti Sethi' },
    { rank: 34, name: 'Sanjay Gupta' },
    { rank: 35, name: 'Vinita Iyer' },
    { rank: 36, name: 'Gaurav Choudhary' },
    { rank: 37, name: 'Nidhi Rawat' },
    { rank: 38, name: 'Suraj Singh' },
    { rank: 39, name: 'Rekha Mishra' },
    { rank: 40, name: 'Ramesh Kumar' },
    { rank: 41, name: 'Asha Yadav' },
    { rank: 42, name: 'Ravi Patil' },
    { rank: 43, name: 'Dinesh Agarwal' },
    { rank: 44, name: 'Sanjana Rao' },
    { rank: 45, name: 'Rita Sharma' },
    { rank: 46, name: 'Vinay Chaudhary' },
    { rank: 47, name: 'Sheetal Gupta' },
    { rank: 48, name: 'Rohan Bhatia' },
    { rank: 49, name: 'Kriti Sharma' },
    { rank: 50, name: 'Vikas Yadav' },
];

const referTopPlayers = [
    { rank: 1, name: 'vibhuti joshi' },
    { rank: 2, name: 'Vaibhav Singh' },
    { rank: 3, name: 'Mohit Sharma' },
    { rank: 4, name: 'Sapna Mehta' },
    { rank: 5, name: 'Rahul Desai' },
    { rank: 6, name: 'Pooja Sethi' },
    { rank: 7, name: 'Vijay Patil' },
    { rank: 8, name: 'Gauri Joshi' },
    { rank: 9, name: 'Arvind Gupta' },
    { rank: 10, name: 'Suman Rani' },
    { rank: 11, name: 'Aniket Mehta' },
    { rank: 12, name: 'Sunil Sharma' },
    { rank: 13, name: 'Riya Bansal' },
    { rank: 14, name: 'Dinesh Rathi' },
    { rank: 15, name: 'Lakshmi Rao' },
    { rank: 16, name: 'Vikas Yadav' },
    { rank: 17, name: 'Kiran Sharma' },
    { rank: 18, name: 'Rajesh Rathi' },
    { rank: 19, name: 'Amit Agarwal' },
    { rank: 20, name: 'Pallavi Yadav' },
    { rank: 21, name: 'Siddharth Rao' },
    { rank: 22, name: 'Deepa Singh' },
    { rank: 23, name: 'Harish Verma' },
    { rank: 24, name: 'Ritesh Kumar' },
    { rank: 25, name: 'Neelam Patil' },
    { rank: 26, name: 'Mohan Gupta' },
    { rank: 27, name: 'Nisha Bhatia' },
    { rank: 28, name: 'Surya Yadav' },
    { rank: 29, name: 'Akash Sharma' },
    { rank: 30, name: 'Sonal Gupta' },
    { rank: 31, name: 'Jitendra Kumar' },
    { rank: 32, name: 'Meena Verma' },
    { rank: 33, name: 'Vivek Yadav' },
    { rank: 34, name: 'Kavita Rathi' },
    { rank: 35, name: 'Anjali Gupta' },
    { rank: 36, name: 'Rajeev Singh' },
    { rank: 37, name: 'Ankita Agarwal' },
    { rank: 38, name: 'Raghav Sharma' },
    { rank: 39, name: 'Seema Chaudhary' },
    { rank: 40, name: 'Rajesh Patil' },
    { rank: 41, name: 'Gopal Rani' },
    { rank: 42, name: 'Tushar Sharma' },
    { rank: 43, name: 'Kamla Devi' },
    { rank: 44, name: 'Suresh Kumar' },
    { rank: 45, name: 'Priya Singh' },
    { rank: 46, name: 'Alok Yadav' },
    { rank: 47, name: 'Neeraj Gupta' },
    { rank: 48, name: 'Bhavna Sharma' },
    { rank: 49, name: 'kundan Kumar' },
    { rank: 50, name: 'Indu Verma' },
];



    // Function to generate random amounts
    const getRandomAmount = (rank, isTopThree) => {
        const amount = isTopThree 
            ? (Math.random() * 9 + 1).toFixed(2) * 100000  // Random amount in lakhs for top three
            : (Math.random() * 90 + 1).toFixed(0) * 1000;  // Random amount in thousands for others
        return amount.toLocaleString(); // Format with commas
    };

    // Function to render player cards with earnings
    const renderPlayerCards = (players) => (
        <div className={css.playerList}>
            {/* Top 3 in "tower" style */}
            <div className={css.topThreeContainer}>
                {[0, 1, 2].map(index => (
                    <div key={players[index].rank} className={`${css.topCard} ${index === 1 ? css.largeCard : css.smallCard}`}>
                        <div className={css.rankBadge} style={{ backgroundColor: index === 1 ? '#FFD700' : index === 0 ? '#C0C0C0' : '#CD7F32' }}>
                            {index + 1}
                        </div>
                        <img src={`https://i.postimg.cc/${index === 0 ? 'd06sPLgg/Avatar2' : index === 1 ? 'K8NrKhVB/Avatar6-3ba711837b4c198aee1a' : 'NM8PPHVr/Avatar3'}.png`} width="30" height="30" alt="Avatar" />
                        <span className={css.playerName}>{players[index].name}</span>
                       {/* <span className={css.playerAmount} style={{ color: 'green', fontWeight: 'bold', fontSize: 'small' }}>
                            <span style={{ fontSize: '0.5em', marginRight: '5px' }}></span>  {getRandomAmount(index + 1, true)}
                        </span> */} 
                    </div>
                ))}
            </div>

            {/* Other players */}
            <div className={css.otherPlayers}>
                {players.slice(3).map(player => (
                    <div key={player.rank} className={css.playerCard} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={css.rankBadge}>{player.rank}</div>
                            <img src="https://i.postimg.cc/KYz7Bg75/Avatar1.png" width="20" height="20" alt="Avatar" /> &nbsp;
                            <span className={css.playerName}>{player.name}</span>
                        </div>
                      {/*  <span className={css.playerAmount} style={{ color: 'green', fontWeight: 'bold', fontSize: 'small' }}>
                            <span style={{ fontSize: '0.5em', marginRight: '10px' }}>Cash Won</span> {getRandomAmount(player.rank, false)}
                       </span> */}
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <div className="leftContainer" style={{ minHeight: '100vh', paddingBottom: '30px' }}>
            <div className={css.leaderboardContainer}>
                <div className={css.card}>
                    <div className="card mt-3" style={{ borderTop: '1px solid rgba(128, 128, 128, 0.5)', borderRadius: '4px', width: '100%', margin: '0 auto', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)', backgroundColor: '#ffffff', borderBottom: 'none' }}>
                        <div className="card-header text-center" style={{ fontWeight: 'bold', fontSize: '14px', letterSpacing: '0.9px', backgroundColor: '#333333', padding: '8px 16px', textAlign: 'center', color: '#ffffff' }}>
                            Leaderboard
                        </div>
                    </div>

                    {/* Tab Buttons */}
                    <div className={`${css.buttonContainer} mt-3`}>
                        <button 
                            className={`${css.button} ${activeTab === 'game' ? css.activeButton : ''}`} 
                            onClick={() => setActiveTab('game')}
                        >
                            Game Top Players
                        </button>
                        <button 
                            className={`${css.button} ${activeTab === 'refer' ? css.activeButton : ''}`} 
                            onClick={() => setActiveTab('refer')}
                        >
                            Refer Top Players
                        </button>
                    </div>

                    {/* Player Cards */}
                    {activeTab === 'game' ? renderPlayerCards(gameTopPlayers) : renderPlayerCards(referTopPlayers)}
                </div>
            </div>
        </div>
    );
};

export default Leaderboard;