import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../Components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Redeem = ({ walletUpdate }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;
  const history = useHistory();
  
  const [amount, setamount] = useState();
  const [userAllData, setUserAllData] = useState();
  const [loading, setLoading] = useState(false);

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios.get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });
  };

  useEffect(() => {
    const access_token = localStorage.getItem("token");
    if (!access_token) {
      history.push("/login");
    } else {
      role();
    }
  }, []);

  const deposit = () => {
    if (amount && amount >= 100 && amount <= 100000) {
      setLoading(true); // Show spinner
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      axios.post(baseUrl + `referral/to/wallet`, { amount }, { headers })
        .then((res) => {
          walletUpdate();
          setLoading(false); // Hide spinner
          if (res.data.msg === "Invalid Amount") {
            toast.warning("You don't have sufficient amount.");
          } else {
            toast.success("Redeem successful!");
          }
        })
        .catch((e) => {
          setLoading(false); // Hide spinner
          if (e.response.status === 401) {
            localStorage.removeItem("token");
            window.location.reload();
            history.push("/login");
          }
        });
    } else {
      let msg = "Please enter an amount.";
      if (amount < 100) {
        msg = "Amount should be more than ₹100.";
      } else if (amount > 100000) {
        msg = "Amount should not exceed ₹100,000.";
      }
      toast.error(msg);
    }
  };

  return (
    <>
      <Header user={userAllData} />
      <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", minHeight: "100vh", backgroundColor: "#f0f0f0", padding: "60px 0" }}>
        <div style={{ marginTop: "70px", position: "relative", top: "20px" }}></div>

        <div className="card mt-3" style={{ border: '1px solid rgba(128, 128, 128, 0.5)', borderRadius: '4px', width: '93%', margin: '0 auto', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)', backgroundColor: '#ffffff' }}>
          <div className="card-header text-center" style={{ fontWeight: 'bold', fontSize: '14px', letterSpacing: '0.9px', backgroundColor: '#878787', padding: '8px 16px', textAlign: 'center', color: '#ffffff' }}>
            Redeem your Referral Balance
          </div>

          <div className="card-body p-2">
            <div className="text-relative position-relative">
              <div className="games-section-headline" style={{ marginBottom: "15px", fontSize: '0.85em', color: "#555" }}>
                Enter Amount (Min: ₹100, Max: ₹100,000)
              </div>
              <input
                type="number"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #000",
                  backgroundColor: "#fff",
                  color: "#000",
                  marginBottom: "10px",
                }}
                value={amount}
                placeholder="Enter Amount"
                onChange={(e) => setamount(parseInt(e.target.value))}
              />
              <small style={{ display: "block", color: "#555", marginBottom: "10px" }}>Minimum withdrawal amount is ₹100.</small>
              <p style={{ color: "#555", marginBottom: "20px" }}>Money will be added to your cash balance.</p>
              <button
                className="redeem-button"
                style={{
                  width: "100%",
                  padding: "8px",
                  backgroundColor: "black", // Change button color to green
                  color: "#fff",
                  borderRadius: "4px",
                  border: "none",
                  fontSize: "16px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={deposit}
                disabled={loading} // Disable button during loading
              >
                {loading ? (
                  <div className="spinner" style={{ border: "2px solid #fff", borderTop: "2px solid transparent", width: "20px", height: "20px", borderRadius: "50%", animation: "spin 1s linear infinite" }} />
                ) : "Redeem"}
              </button>
            </div>
          </div>
        </div>
      </div>
     <ToastContainer
  style={{ marginBottom: '25px' }}
  position="top-right"
  autoClose={3000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
/>      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </>
  );
};

export default Redeem;